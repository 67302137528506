<script setup>
import {
  computed,
  onBeforeUnmount,
  reactive,
  ref,
  watchEffect,
  defineAsyncComponent
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { checkCustomerSell, getCustomerInfo } from '@service/CustomerService'

const store = useStore()
const route = useRoute()
const router = useRouter()
const btnLoading = ref(false)
const modals = reactive({
  register: false,
  plan: false
})
const currentPage = ref('footer-menu')
const currentComponent = computed(() => {
  switch (currentPage.value) {
    case 'footer-menu':
      return defineAsyncComponent(() => import('./FooterMenu'))
    case 'footer-sell-form-1':
      return defineAsyncComponent(() => import('./FooterSellForm1'))
    case 'footer-offer-form':
      return defineAsyncComponent(() => import('./FooterOfferForm'))
    case 'footer-gift-form':
      return defineAsyncComponent(() => import('./FooterGiftForm'))

    default:
      return defineAsyncComponent(() => import('./FooterMenu'))
  }
})
const formHeight = ref('190px')

// Handle outside button for register modal
const registerModal = computed({
  get() {
    return store.state.footer.registerModal
  },
  set(value) {
    store.dispatch('handleModalFooter', value)
  }
})
watchEffect(() => {
  if (registerModal.value) updateForm('sell')
})
const updateForm = (form) => {
  switch (form) {
    case 'menu':
      formHeight.value = '190px'
      currentPage.value = 'footer-menu'
      break

    case 'sell':
      formHeight.value = '317px'
      currentPage.value = 'footer-sell-form-1'
      break

    case 'offer':
      formHeight.value = '254px'
      currentPage.value = 'footer-offer-form'
      break

    case 'gift':
      formHeight.value = '254px'
      currentPage.value = 'footer-gift-form'
      break

    default:
      break
  }
}

// fetch plans
const plans = computed(() => store.state.plan.sellPlans)
const selectedPlanId = ref(null)
const sellLoading = ref(false) // Register sell button in bind to two loadings
const customerInfo = reactive({
  name: '',
  group: '',
  level: ''
})
const mobile = computed(() => store.state.footer.customerMobile)
const price = computed(() => store.state.footer.customerPrice)

const submitSell1 = async (mode) => {
  // If there is only 0
  if (mode == 'direct') {
    handleSelectPlan(mode, plans.value[0]['id'])
  } else if (mode == 'select') {
    // Set Loading for getting user info
    sellLoading.value = true
    try {
      const { data } = await getCustomerInfo(mobile.value)
      customerInfo['name'] = data['name']
      customerInfo['level'] = data['level']
      customerInfo['group'] = data['group']
      modals['register'] = false
      registerModal.value = false
      modals['plan'] = true
    } catch (ex) {
      switch (ex.response.status) {
        case 404:
          customerInfo['name'] = 'مشتری جدید'
          customerInfo['level'] = 'بدون گروه'
          customerInfo['group'] = 'پایه'
          modals['register'] = false
          registerModal.value = false
          modals['plan'] = true
          break

        default:
          store.dispatch('addToast', {
            type: 'error',
            message: 'درخواست شما موفقیت‌آمیز نبود.'
          })
          refreshForm()
      }
    } finally {
      sellLoading.value = false
    }
  } else {
    refreshForm()
  }
}

const submitInquiry = () => {
  const mobile = computed(() => store.state.footer.customerMobile)
  router.push({ name: 'FooterInquiry', params: { mobile: mobile.value } })
  refreshForm()
}

const submitGift = (type) => {
  if (type === 'success') {
    const code = computed(() => store.state.footer.giftCode)
    router.push({ name: 'GiftInquiry', params: { code: code.value } })
    refreshForm()
  } else {
    refreshForm()
  }
}

// submit plan
const handleSelectPlan = async (mode, id) => {
  if (sellLoading.value || btnLoading.value) return

  selectedPlanId.value = id
  if (mode == 'direct') sellLoading.value = true
  else btnLoading.value = true

  try {
    const { data } = await checkCustomerSell({
      mobile: mobile.value,
      price: price.value,
      planId: id
    })
    const isNew = data.is_new
    const isComplete = data.completed
    store.commit('SET_CUSTOMER_ID', data.customer_id)

    // store.dispatch('addToast', {
    //   type: 'success',
    //   message: 'مبلغ 500 ریال از موجودی شما پرداخت شد. '
    // })

    // Check for Plan pages to refresh immidiately
    if (route.path == `/store/plan/${id}`) {
      store.dispatch('plan/fetchItem', { part: 'plan', id })
    } else if (route.path == `/store/plan/transactions/${id}`) {
      store.dispatch('plan/clearBackupItems')
      store.dispatch('plan/fetchItems', {
        part: 'transaction',
        backup: true,
        query: { plan: id }
      })
    }

    store.dispatch('fetchHomeData')
    refreshForm()

    // if mode == select no neeed to these two lines
    btnLoading.value = false
    modals['plan'] = false

    if (isNew || !isComplete) {
      store.dispatch('addToast', {
        type: 'error',
        message: 'مشتری را ثبت نمایید.'
      })
      store.dispatch('handleCustomerModal', true)
    } else {
      store.dispatch('addToast', {
        type: 'success',
        message: `فروش به نام "${data['customer_name']}"  ثبت شد.`
      })
    }
  } catch (ex) {
    switch (ex.response.status) {
      case 402:
        store.dispatch('profile/handleCreditModal', {
          status: 'open',
          type: 'credit'
        })
        break
      default:
        store.dispatch('addToast', {
          type: 'error',
          message: 'درخواست شما موفقیت‌آمیز نبود.'
        })
    }
  } finally {
    btnLoading.value = false
    sellLoading.value = false
  }
}

// refresh everything
const refreshForm = () => {
  store.dispatch('clearFooterForm')
  modals['register'] = false
  registerModal.value = false
  modals['plan'] = false
  updateForm('menu')
  btnLoading.value = false
  selectedPlanId.value = null
  formHeight.value = '190px'
}

onBeforeUnmount(() => {
  registerModal.value = false
})
</script>

<template>
  <li class="footer__item" @click="modals['register'] = true">
    <a class="footer__link">
      <svg
        viewBox="0 0 700 512"
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd; width: 40px; height: 40px;"
        class="footer__icon icon--gold"
      >
        <path
          d="M89.79 254.24c0.01,0.56 0.02,1.12 0.03,1.68l0 0.16c-0.01,0.56 -0.02,1.12 -0.03,1.67l0.13 2.54 0 0.14c0,0.23 0.01,0.47 0.03,0.7l0 0.14c5.02,139.99 119.94,250.73 260.04,250.73 139.36,0 254,-109.64 259.96,-248.86l0.02 -0.32 0.05 -0.31c0.13,-0.76 0.19,-1.53 0.19,-2.29 0,-1.38 -0.02,-2.76 -0.04,-4.14l0 -0.16c0.02,-1.38 0.04,-2.76 0.04,-4.14 0,-0.76 -0.06,-1.53 -0.19,-2.29l-0.05 -0.31 -0.02 -0.32c-5.96,-139.22 -120.6,-248.86 -259.96,-248.86 -140.09,0 -255.02,110.73 -260.04,250.72l0 0.13c-0.02,0.24 -0.03,0.48 -0.03,0.73l0 0.13 -0.13 2.53zm493.46 1.85c-2.32,126.93 -106.3,228.99 -233.26,228.99 -126.95,0 -230.91,-102.07 -233.22,-228.99l0 -0.18c2.31,-126.92 106.26,-228.99 233.22,-228.99 126.96,0 230.94,102.06 233.26,228.99l0 0.18zm-246.72 -145.48l0 238.29 -65.79 -65.79c-5.25,-5.25 -13.78,-5.25 -19.03,0 -5.25,5.25 -5.25,13.78 0,19.03l88.37 88.38c2.61,2.6 6.12,4.01 9.81,3.94l0.2 0c3.67,0.07 7.2,-1.35 9.8,-3.94l88.37 -88.38c5.26,-5.26 5.26,-13.77 0,-19.03 -5.26,-5.25 -13.77,-5.26 -19.03,0l-65.78 65.79 0 -238.29c0,-7.44 -6.02,-13.46 -13.46,-13.46 -7.43,0 -13.46,6.02 -13.46,13.46z"
        />
      </svg>
    </a>
  </li>
  <modal
    type="bottom"
    :is-open="modals['register'] || registerModal"
    @modalClosed="refreshForm"
    :height="formHeight"
  >
    <component
      :is="currentComponent"
      :sell-loading="sellLoading"
      @changeForm="updateForm"
      @submitSellForm1="submitSell1"
      @submitInquiry="submitInquiry"
      @submitGiftForm="submitGift"
    />
  </modal>
  <modal @modalClosed="refreshForm" :is-open="modals['plan']" height="405px">
    <div class="plans-wrapper">
      <div class="table">
        <div class="table__row">
          <h5 class="table__cell">نام مشتری:</h5>
          <h6 class="table__cell">{{ customerInfo['name'] }}</h6>
        </div>
        <div class="table__row">
          <h5 class="table__cell">سطح مشتری:</h5>
          <h6 class="table__cell">{{ customerInfo['group'] }}</h6>
        </div>
        <div class="table__row">
          <h5 class="table__cell">گروه مشتری:</h5>
          <h6 class="table__cell">{{ customerInfo['level'] }}</h6>
        </div>
      </div>
      <div class="cmp-items-wrapper">
        <h3 class="plans-title">
          اعتبار
        </h3>
        <a
          v-for="plan in plans"
          :key="plan['id']"
          class="plan-btn"
          @click="() => handleSelectPlan('select', plan['id'])"
        >
          <template v-if="plan['const_percent'] === 0">
            <span style="font-size: 18px;">بدون اعتبار</span>
          </template>
          <template v-else>
            <spinner v-if="btnLoading && selectedPlanId === plan['id']" />
            <svg class="background-svg" viewBox="0 0 512 512" v-else>
              <path
                d="M251.81 511.42c0.02,0 0.03,0 0.05,0.01 0.12,0.03 0.25,0.05 0.38,0.09 0.45,0.11 0.91,0.21 1.37,0.28 0.19,0.03 0.39,0.06 0.58,0.09 0.55,0.06 1.11,0.11 1.67,0.11 0.06,0 0.13,0.01 0.12,0.01 0.07,0 0.13,-0.01 0.2,-0.01 0.55,0 1.11,-0.05 1.67,-0.11 0.19,-0.03 0.38,-0.06 0.57,-0.09 0.47,-0.07 0.93,-0.17 1.38,-0.29 0.13,-0.03 0.26,-0.05 0.29,-0.06 0.04,-0.01 0.07,-0.02 0.11,-0.03 0.02,0 0.06,-0.02 0.1,-0.03 0.17,-0.05 0.34,-0.12 0.52,-0.17 0.27,-0.09 0.55,-0.19 0.82,-0.29 0.08,-0.04 0.17,-0.08 0.26,-0.11 0.1,-0.05 0.19,-0.09 0.28,-0.13 0.24,-0.11 0.48,-0.22 0.71,-0.34 0.14,-0.08 0.27,-0.15 0.4,-0.23 0.27,-0.15 0.53,-0.3 0.78,-0.47 0.13,-0.08 0.24,-0.17 0.37,-0.25 0.25,-0.18 0.5,-0.37 0.68,-0.52 0.07,-0.06 0.14,-0.11 0.12,-0.09 0.05,-0.04 0.11,-0.09 0.11,-0.09 0.05,-0.04 0.09,-0.09 0.14,-0.13 0.24,-0.21 0.47,-0.42 0.68,-0.64 0.08,-0.08 0.16,-0.15 0.21,-0.19l242.62 -258.66c3.45,-3.68 3.97,-8.83 1.31,-12.98l-82.47 -128.32c-2.36,-3.68 -6.83,-5.95 -11.67,-5.95l-320.33 0c-4.84,0 -9.31,2.27 -11.67,5.95l-82.48 128.32c-2.66,4.15 -2.14,9.3 1.31,12.98l242.63 258.63c0.08,0.08 0.16,0.14 0.23,0.22 0.22,0.22 0.44,0.43 0.67,0.64 0.06,0.04 0.11,0.09 0.08,0.07 0.04,0.04 0.09,0.07 0.11,0.09 0.07,0.05 0.13,0.1 0.14,0.11 0.24,0.19 0.48,0.38 0.74,0.56 0.11,0.08 0.23,0.16 0.35,0.24 0.26,0.17 0.53,0.33 0.8,0.49 0.13,0.07 0.25,0.14 0.38,0.21 0.23,0.12 0.48,0.23 0.68,0.33 0.1,0.04 0.21,0.09 0.31,0.14 0.09,0.04 0.19,0.08 0.28,0.12 0.27,0.1 0.55,0.19 0.82,0.29 0.17,0.05 0.35,0.12 0.52,0.17 0.02,0.01 0.05,0.02 0.07,0.03zm36.76 -386.38l46.2 105.11 -157.52 0 46.22 -105.11 65.1 0zm47.14 128.32l-79.69 209.96 -79.7 -209.96 159.39 0zm-187.54 0l72.49 190.98 -179.17 -190.98 106.68 0zm215.69 0l106.65 0 -179.14 190.97 72.49 -190.97zm112.01 -23.21l-112.48 0 -46.21 -105.11 91.14 0 67.55 105.11zm-372.18 -105.11l91.16 0 -46.22 105.11 -112.5 0 67.56 -105.11z"
              />
            </svg>
            <p v-text="plan['const_percent'] + '%'" />
          </template>
        </a>
      </div>
    </div>
  </modal>
</template>

<style lang="scss" scoped>
.plans-wrapper {
  background-color: #ddd;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 10px;
  .plan-btn {
    background-color: #fff;
    height: 100px;
    margin: 3px 0;
    width: 94%;

    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    svg {
      fill: #c69d4e;
      width: 38px;
      height: 38px;
      position: relative;
      bottom: 2px;
    }
    p {
      font-size: 36px;
      font-weight: bold;
      display: inline-block;
      position: relative;
      top: 6px;
      margin-right: 8px;
    }
  }
  .plans-title {
    font-size: 18px;
    margin: 20px 0 5px;
  }
}

.table {
  border: none;
  margin-top: 10px;
  background-color: #f8f8f8;
}
.table__row {
  height: 25px;
}
</style>
