<template>
  <ul :class="['footer', isIphone && 'footer--iphone']" v-show="show">
    <li class="footer__item">
      <router-link class="footer__link" :to="{ name: 'Home' }">
        <svg
          width="512px"
          height="512px"
          viewBox="0 0 512 512"
          class="footer__icon"
        >
          <g id="Layer_x0020_1">
            <metadata id="CorelCorpID_0Corel-Layer" />
            <path
              d="M256 10.01l-256 217.71 0 293.21 203.97 0 0 -186.68c0,-28.61 23.41,-52.03 52.03,-52.03 28.62,0 52.03,23.42 52.03,52.03l0 186.68 203.97 0 0 -293.21 -256 -217.71zm0.29 33.19l229.48 194.08 0 257.42 -151.52 0 0 -160.45c0,-43.09 -35.16,-78.25 -78.25,-78.25 -43.09,0 -78.26,35.16 -78.26,78.25l0 160.45 -151.52 0 0 -257.41 230.07 -194.09z"
            />
          </g>
        </svg>
      </router-link>
    </li>
    <li class="footer__item item--ticket">
      <span class="footer__ticket-count" v-if="ticketCount > 0">{{
        ticketCount
      }}</span>
      <router-link :to="{ name: 'Ticket' }" class="footer__link">
        <svg
          width="600px"
          height="512px"
          viewBox="0 0 600 512"
          class="footer__icon"
        >
          <g id="Layer_x0020_1">
            <metadata id="CorelCorpID_0Corel-Layer" />
            <path
              d="M575.76 361.11l-0.01 -307.91c0,-29.17 -23.57,-52.94 -52.67,-53.2l-445.61 0c-29.36,0 -53.22,23.84 -53.22,53.21l0 307.89c0,29.33 23.82,53.31 53.21,53.21l75.57 -0.28 0 83.65c0,5.3 3.11,10.11 7.92,12.3 4.76,2.17 10.46,1.41 14.36,-1.99l105.82 -94.01 241.4 0.34c29.37,0.04 53.23,-23.85 53.23,-53.21zm-395.66 106.86l0 -67.38c0,-7.4 -6.01,-13.55 -13.45,-13.53l-89.2 0.21c-6.99,0.01 -13.52,-2.75 -18.45,-7.69 -4.94,-4.94 -7.69,-11.48 -7.69,-18.47l0 -307.9c0,-6.99 2.75,-13.53 7.69,-18.47 4.94,-4.93 11.48,-7.69 18.47,-7.69l445.06 0c6.99,0 13.53,2.76 18.47,7.7 4.93,4.93 7.68,11.47 7.68,18.46l0.01 307.9c0,6.99 -2.75,13.53 -7.69,18.47 -4.94,4.93 -11.49,7.69 -18.47,7.68l-246.49 -0.33c-3.16,0 -6.39,1.17 -8.72,3.2l-87.22 77.84z"
            />
            <path
              d="M132.49 229.61l276.62 0c6.72,0 12.22,6.08 12.22,13.52l0 0c0,7.44 -5.5,13.53 -12.22,13.53l-276.62 0c-6.72,0 -12.21,-6.09 -12.21,-13.53l0 0c0,-7.44 5.49,-13.52 12.21,-13.52zm0 -76.08l134.87 0c6.72,0 12.21,6.09 12.21,13.53l0 0c0,7.43 -5.5,13.52 -12.21,13.52l-134.87 0c-6.72,0 -12.21,-6.09 -12.21,-13.52l0 0c0,-7.44 5.49,-13.53 12.21,-13.53z"
            />
          </g>
        </svg>
      </router-link>
    </li>
    <footer-register />
    <footer-search />
    <!-- <li class="footer__item">
      <a
        class="footer__link"
        @click="$store.dispatch('handleCustomerModal', true)"
      >
        <svg
          class="footer__icon"
          style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
          viewBox="0 0 700 512"
        >
          <path
            d="M93.53 512l27.14 0c3.7,-122.99 104.9,-221.89 228.77,-221.89 79.98,0 145.05,-65.08 145.05,-145.06 0,-79.97 -65.07,-145.05 -145.05,-145.05 -79.98,0 -145.05,65.07 -145.05,145.05 0,47.94 23.62,92.43 62.99,119.54l11.39 7.85 -13.07 4.51c-36.72,12.66 -69.82,33.55 -97.28,61.01 -46.71,46.71 -73.14,108.27 -74.89,174.04zm255.91 -249.01c-65.03,0 -117.93,-52.91 -117.93,-117.94 0,-65.03 52.9,-117.93 117.93,-117.93 65.03,0 117.93,52.9 117.93,117.93 0,65.03 -52.9,117.94 -117.93,117.94zm170.73 49.31l-27.12 0 0 86.29 -86.29 0 0 27.12 86.29 0 0 86.29 27.12 0 0 -86.29 86.3 0 0 -27.12 -86.3 0 0 -86.29z"
          />
        </svg>
      </a>
    </li> -->
    <footer-customer />
  </ul>
</template>

<script>
import FooterRegister from '@view/store/footer/subComponents/FooterRegister'
import FooterSearch from '@view/store/footer/subComponents/FooterSearch'
import FooterCustomer from '@view/store/footer/subComponents/FooterCustomer'

export default {
  name: 'FixedFooter',
  components: {
    FooterRegister,
    FooterSearch,
    FooterCustomer
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      show: true
    }
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight
    }
  },
  computed: {
    isIphone() {
      return this.$store.getters.isIphoneX
    },
    ticketCount() {
      return this.$store.state.ticketCount
    }
  },
  watch: {
    windowHeight(val) {
      const { availHeight } = window.screen

      if (Math.ceil(100 * (val / availHeight)) < 80) this.show = false
      else this.show = true
    }
  }
}
</script>

<style scoped lang="scss"></style>
