export default {
  START_LOADING(state) {
    state.isLoading = true
  },
  STOP_LOADING(state) {
    state.isLoading = false
  },
  START_BTN_LOADING(state) {
    state.btnLoading = true
  },
  STOP_BTN_LOADING(state) {
    state.btnLoading = false
  },
  START_MODAL_LOADING(state) {
    state.modalLoading = true
  },
  STOP_MODAL_LOADING(state) {
    state.modalLoading = false
  },
  INCREMENT_PAGE(state) {
    state.page++
  },
  RESET_PAGE(state) {
    state.page = 1
  },
  SET_LAST_PAGE(state) {
    state.page = -1
  },
  THEME_IS_DARK(state) {
    state.darkMode = true
  },
  THEME_IS_LIGHT(state) {
    state.darkMode = false
  },
  SET_ERROR(state, status) {
    state.errorStatus = status
  },
  SET_ERROR_MSG(state, text) {
    state.errorMsg = text
  },
  SET_SUCCESS_MSG(state, text) {
    state.successMsg = text
  },
  START_PAGE_LOADING(state) {
    state.pageLoading = true
  },
  STOP_PAGE_LOADING(state) {
    state.pageLoading = false
  }
}
