<template>
  <div class="observer" />
</template>

<script>
export default {
  name: 'Observer',
  props: {
    triggerMargin: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      observer: null
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting) {
          this.$emit('intersect')
        }
      },
      {
        rootMargin: this.triggerMargin + '%'
      }
    )

    this.observer.observe(this.$el)
  }
}
</script>

<style lang="scss" scoped>
.observer {
  height: 1px;
}
</style>
