const Gift = () =>
  import(/* webpackChunkName: "gift" */ '@view/store/gift-card/Gift')
const GiftNew = () =>
  import(/* webpackChunkName: "gift" */ '@view/store/gift-card/GiftNew')
const GiftHome = () =>
  import(/* webpackChunkName: "gift" */ '@view/store/gift-card/GiftHome')
const GiftStatus = () =>
  import(/* webpackChunkName: "gift" */ '@view/store/gift-card/GiftStatus')
const GiftShow = () =>
  import(/* webpackChunkName: "gift" */ '@view/store/gift-card/GiftShow')
const GiftTransactions = () =>
  import(
    /* webpackChunkName: "gift" */ '@view/store/gift-card/GiftTransactions'
  )
const GiftInquiry = () =>
  import(/* webpackChunkName: "gift" */ '@view/store/gift-card/GiftInquiry')
const GiftStat = () =>
  import(/* webpackChunkName: "gift" */ '@view/store/gift-card/GiftStat')
const GiftPrint = () =>
  import(/* webpackChunkName: "gift" */ '@view/store/gift-card/GiftPrint')

export default [
  {
    path: '/store/bon',
    name: 'Gift',
    component: Gift,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'GiftHome',
        component: GiftHome
      },
      {
        path: 'new',
        name: 'GiftNew',
        component: GiftNew
      },
      {
        path: 'stat',
        name: 'GiftStat',
        component: GiftStat
      }
    ]
  },
  {
    path: '/store/bon/:id',
    name: 'GiftShow',
    component: GiftShow,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/bon/:id/:status',
    name: 'GiftStatus',
    component: GiftStatus,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/bon/print',
    name: 'GiftPrint',
    component: GiftPrint,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/sell/bon/:code',
    name: 'GiftInquiry',
    component: GiftInquiry,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/sell/bon/:code/transaction/:id',
    name: 'GiftTransactions',
    component: GiftTransactions,
    props: true,
    meta: { requiresAuth: true }
  }
]
