const Receiver = () =>
  import(/* webpackChunkName: "receiver" */ '@view/store/receiver/Receiver')
const ReceiverHome = () =>
  import(/* webpackChunkName: "receiver" */ '@view/store/receiver/ReceiverHome')
const ReceiverStat = () =>
  import(/* webpackChunkName: "receiver" */ '@view/store/receiver/ReceiverStat')
const ReceiverShow = () =>
  import(/* webpackChunkName: "receiver" */ '@view/store/receiver/ReceiverShow')
const ReceiverNew = () =>
  import(/* webpackChunkName: "receiver" */ '@view/store/receiver/ReceiverNew')
const ReceiverTransaction = () =>
  import(
    /* webpackChunkName: "receiver" */ '@view/store/receiver/ReceiverTransaction'
  )
const ReceiverCheckout = () =>
  import(
    /* webpackChunkName: "receiver" */ '@view/store/receiver/ReceiverCheckout'
  )
const ReceiverCheckoutShow = () =>
  import(
    /* webpackChunkName: "receiver" */ '@view/store/receiver/ReceiverCheckoutShow'
  )

export default [
  {
    path: '/store/cashier',
    name: 'Receiver',
    component: Receiver,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'new/:mode/:id?',
        name: 'ReceiverNew',
        component: ReceiverNew,
        props: true
      },
      {
        path: 'stat',
        name: 'ReceiverStat',
        component: ReceiverStat
      },
      {
        path: '',
        name: 'ReceiverHome',
        component: ReceiverHome
      }
    ]
  },
  {
    path: '/store/cashier/:id',
    name: 'ReceiverShow',
    component: ReceiverShow,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/cashier/:id/transaction',
    name: 'ReceiverTransaction',
    component: ReceiverTransaction,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/cashier/:id/checkout',
    name: 'ReceiverCheckout',
    component: ReceiverCheckout,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/cashier/:id/checkout/:checkoutId',
    name: 'ReceiverCheckoutShow',
    component: ReceiverCheckoutShow,
    props: true,
    meta: { requiresAuth: true }
  }
]
