const Offer = () =>
  import(/* webpackChunkName: "offer" */ '@view/store/offer/Offer')
const OfferHome = () =>
  import(/* webpackChunkName: "offer" */ '@view/store/offer/OfferHome')
const CustomerFilter = () =>
  import(/* webpackChunkName: "offer" */ '@view/store/customer/CustomerFilter')
const OfferNew = () =>
  import(/* webpackChunkName: "offer" */ '@view/store/offer/OfferNew')
const OfferShow = () =>
  import(/* webpackChunkName: "offer" */ '@view/store/offer/OfferShow')
const OfferStatus = () =>
  import(/* webpackChunkName: "offer" */ '@view/store/offer/OfferStatus')
const OfferInquiry = () =>
  import(/* webpackChunkName: "offer" */ '@view/store/offer/OfferInquiry')
const OfferStat = () =>
  import(/* webpackChunkName: "offer" */ '@view/store/offer/OfferStat')

export default [
  {
    path: '/store/offer',
    name: 'Offer',
    component: Offer,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'OfferHome',
        component: OfferHome
      },
      {
        path: 'new',
        name: 'OfferNew',
        component: OfferNew
      },
      {
        path: 'stat',
        name: 'OfferStat',
        component: OfferStat
      },
      {
        path: 'filter/:destination',
        name: 'OfferCustomerFilter',
        component: CustomerFilter,
        props: true
      }
    ]
  },
  {
    path: '/store/offer/:id',
    name: 'OfferShow',
    component: OfferShow,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/offer/customer/:id/:status',
    name: 'OfferStatus',
    component: OfferStatus,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/sell/offer/:mobile',
    name: 'OfferInquiry',
    component: OfferInquiry,
    props: true,
    meta: { requiresAuth: true }
  }
]
