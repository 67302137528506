<template>
  <div class="failed">
    <p class="failed__desc">مشکلی در بارگذاری پیش آمده است!</p>
    <a class="round-btn" @click="$emit('refresh')">
      <svg viewBox="0 0 700 512" class="round-btn__icon">
        <path
          d="M616.57 61l0 0c-7.03,-2.57 -14.88,1.07 -17.45,8.09l-32.33 88.21c-1.26,-3 -2.58,-6 -3.96,-8.98 -59.47,-128.27 -211.66,-184.04 -339.93,-124.57 -128.27,59.46 -184.04,211.66 -124.58,339.93 59.47,128.27 211.66,184.04 339.93,124.57 77.5,-35.93 129.05,-105.61 143.68,-183.61 3.79,-20.23 -21.22,-29.73 -27.47,-0.66 -14.59,67.86 -59.75,128.3 -127.58,159.75 -114.72,53.18 -250.84,3.3 -304.03,-111.42 -53.19,-114.73 -3.3,-250.85 111.42,-304.04 114.73,-53.18 250.85,-3.3 304.03,111.42 2.5,5.4 4.77,10.84 6.82,16.32l-5.13 -1.48 -94 -34.45c-7.03,-2.57 -14.88,1.07 -17.45,8.09l0 0c-2.58,7.03 1.06,14.88 8.09,17.45l124.83 45.75 0 0c7.02,2.57 14.87,-1.07 17.45,-8.09l0 0 45.75 -124.83c2.57,-7.03 -1.07,-14.88 -8.09,-17.45z"
        />
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: 'FailedMode',
  emits: ['refresh']
}
</script>

<style lang="scss" scoped>
.failed {
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 20px;
}
.failed__desc {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0 20px;
  text-align: center;
  line-height: 25px;
  color: #777;
  height: 100px;
}

.round-btn {
  margin-right: 20px;
}
</style>
