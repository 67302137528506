import qs from 'querystring'
import http from './HttpService'

export const getCustomer = (id) => {
  return http.get('/store/customer/' + id + '/')
}
export const searchCustomers = (vars) => {
  const query = qs.stringify({
    ...vars
  })
  return http.get('/store/customer/?' + query)
}
export const checkCustomerSell = ({ mobile, price, planId }) => {
  const formData = new FormData()
  formData.append('mobile', mobile)
  formData.append('price', price)
  formData.append('plan_id', planId)

  return http.post('/store/sell/', formData)
}

// Use for both add and edit
export const addCustomer = (id, data) => {
  const formData = new FormData()
  for (let key of Object.keys(data)) formData.append(key, data[key])
  return http.patch('/store/customer/' + id + '/', formData)
}

export const deleteCustomer = (id) => {
  return http.delete('/store/customer/' + id + '/')
}

export const getCustomerByMobile = ({ mobile }) => {
  const formData = new FormData()
  formData.append('mobile', mobile)

  return http.post('/store/customer/', formData)
}

export const getCustomerOffer = ({ mobile }) => {
  const formData = new FormData()
  formData.append('mobile', mobile)

  return http.post('/store/sell-offer/', formData)
}

export const getCustomerGift = ({ code }) => {
  const formData = new FormData()
  formData.append('code', code)

  return http.post('/store/sell-bon/', formData)
}

export const receiveGiftPrice = ({ code, price }) => {
  const formData = new FormData()
  formData.append('price', price)
  formData.append('code', code)

  return http.put('/store/sell-bon/', formData)
}

export const getCustomerBonus = ({ mobile }) => {
  const formData = new FormData()
  formData.append('mobile', mobile)

  return http.post('/store/sell-bonus/', formData)
}

export const getCustomerGroups = () => {
  return http.get('/store/group/')
}

export const addCustomerGroup = ({ name, price, desc }) => {
  const formData = new FormData()
  formData.append('name', name)
  formData.append('min_buy', price)
  formData.append('desc', desc)

  return http.post('/store/group/', formData)
}
export const initCustomerGroups = (items) => {
  const formData = new FormData()
  for (let key of Object.keys(items)) {
    formData.append(key, items[key])
  }

  return http.post('/store/group/batchInsert/', formData)
}
export const editCustomerGroup = ({ id, name, price, desc }) => {
  const formData = new FormData()
  formData.append('name', name)
  formData.append('min_buy', price)
  formData.append('desc', desc)

  return http.put('/store/group/' + id + '/', formData)
}
export const deleteCustomerGroup = ({ id }) => {
  return http.delete('/store/group/' + id + '/')
}
export const getCustomersInfo = () => {
  return http.get('/store/customer/index/')
}
export const getCustomerCities = () => {
  return http.get('/store/customer/city/')
}
export const getFilteredCustomers = (data) => {
  const formData = new FormData()
  for (let key of Object.keys(data)) formData.append(key, data[key])

  return http.post('/store/customer/count/', formData)
}

// Handle labels
export const getCustomerLabels = () => {
  return http.get('/store/label/')
}
export const addCustomerLabel = ({ name }) => {
  const formData = new FormData()
  formData.append('name', name)

  return http.post('/store/label/', formData)
}
export const editCustomerLabel = ({ id, name }) => {
  const formData = new FormData()
  formData.append('name', name)

  return http.put('/store/label/' + id + '/', formData)
}
export const deleteCustomerLabel = ({ id }) => {
  return http.delete('/store/label/' + id + '/')
}

// List of offers which belong to cusotmer
export const getCustomerOffers = (id) => {
  return http.get('/store/customer/' + id + '/offer/')
}
export const getCustomerBonuses = (id) => {
  return http.get('/store/customer/' + id + '/bonus/')
}

export const getCustomerInfo = (mobile) => {
  const formData = new FormData()
  formData.append('mobile', mobile)

  return http.post('/store/sellInquiry/', formData)
}

// Birthday
export const getCustomerByBirth = (day) => {
  const query = qs.stringify({
    day
  })
  return http.get('/store/birthday/?' + query)
}
