<template>
  <teleport to="#modal" :disabled="!teleport">
    <div
      :class="['modal', type === 'bottom' && 'modal--bottom']"
      v-if="isOpen"
      @click="close($event)"
    >
      <div
        :class="[
          'modal__inner',
          isOpen && 'modal__in',
          !isOpen && 'modal__out',
          title && 'modal--title'
        ]"
        :style="{ height: height }"
      >
        <h2 class="modal__title" v-if="title">{{ title }}</h2>
        <div class="modal__content">
          <slot />
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    type: {
      type: String,
      default: 'center'
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    title: String,
    teleport: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: '65%'
    },
    stayTop: {
      type: Boolean,
      default: false
    }
  },
  emits: ['modalClosed'],
  methods: {
    close($event) {
      if (
        $event.target.classList.contains('modal') ||
        $event.target.classList.contains('modal__inner')
      ) {
        this.$emit('modal-closed')
      }
    },
    applyModalEffect() {
      const body = document.querySelector('body')
      body.style.top = `-${window.scrollY}px`
      body.style.position = 'fixed'
    },
    applyModalEffectStayTop() {
      const body = document.querySelector('body')
      body.style.position = 'fixed'
    },
    removeModalEffect() {
      const body = document.querySelector('body')
      const scrollY = document.body.style.top
      body.style.position = ''
      body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        // When the modal is shown, we want a fixed body
        if (this.stayTop) this.applyModalEffectStayTop()
        else this.applyModalEffect()
      } else {
        // When the modal is hidden, we want to remain at the top of the scroll position
        this.removeModalEffect()
      }
    }
  },
  beforeUnmount() {
    if (this.isOpen) {
      this.removeModalEffect()
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  z-index: 200;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.7);
}

.modal__inner {
  position: fixed;
  overflow: hidden;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  // transform: translate(-50%, -50%);
  height: 65%;
  width: 96%;
  z-index: 201;
  border-radius: 20px;
}
.modal--title {
  // for title height
  padding-bottom: 60px;
}

.modal__content {
  background-color: #fff;
  max-height: 100%;
  min-height: 100px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  border-radius: 0 0 20px 20px;
}

.modal__title {
  position: relative;
  bottom: -1px;
  height: 60px;
  width: 100%;
  background-color: #ddd;
  color: #000;
  text-align: center;
  line-height: 60px;
}

.modal__in {
  animation: modalCome 0.2s ease;
}

// modal modifier
.modal--bottom .modal__inner {
  width: 100%;
  top: auto;
  margin: 0;
  height: 200px;
  border-radius: 40px 40px 0 0;
  transition: all 0.3s;
}
.modal--bottom .modal__content {
  border-radius: 40px 40px 0 0;
}
.modal--bottom .modal__in {
  animation: modalSlide 0.3s ease-in-out;
}
.modal--bottom .modal__out {
  animation: modalSlideOut 0.3s ease-in-out;
}

// Animation
@keyframes modalCome {
  0% {
    opacity: 0;
    transform: translate(0, calc(100vh + 120px));
  }
}

@keyframes modalSlide {
  0% {
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes modalSlideOut {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 100%, 0);
  }
}
</style>
