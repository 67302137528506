<template>
  <modal
    type="bottom"
    :is-open="isOpen"
    @modalClosed="$store.dispatch('profile/handlePayModal', 'close')"
    height="338px"
  >
    <form novalidate class="pay" @submit.prevent="submitForm">
      <h2 class="pay__title">
        افزایش موجودی
      </h2>
      <base-input-number v-model="price" ref="inputNumber" />
      <div class="pay__btns">
        <a class="pay__btn" @click="selectPrice(100000)">100,000</a>
        <a class="pay__btn" @click="selectPrice(1000000)">1,000,000</a>
        <a class="pay__btn" @click="selectPrice(10000000)">
          10,000,000
        </a>
        <a class="pay__btn" @click="selectPrice(500000)">500,000</a>
        <a class="pay__btn" @click="selectPrice(5000000)">5,000,000</a>
        <a class="pay__btn" @click="selectPrice(50000000)">50,000,000</a>
      </div>
      <div class="credit-buttons">
        <button class="button button--black">
          <span class="button__text">پرداخت</span>
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import {
  computed,
  nextTick,
  onBeforeUnmount,
  ref,
  watchEffect
} from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import {
  price as validPrice,
  validateForms,
  schemaMaker
} from '@valid/JoiValidation'
import BaseInputNumber from '@common/BaseInputNumber'

export default {
  name: 'ProfilePayModal',
  components: {
    BaseInputNumber
  },
  setup() {
    // Define main items
    const store = useStore()
    const router = useRouter()
    const isOpen = computed(() => store.state.profile.payModal)
    const price = ref('')

    // form submition
    const isPriceValid = schemaMaker({
      price: validPrice('مبلغ پرداخت', 10000)
    })
    const submitForm = async () => {
      const errors = validateForms({ price: price.value }, isPriceValid)
      if (errors && errors.length > 0) {
        for (let error of errors) {
          const message = {
            type: 'error',
            message: error
          }
          store.dispatch('addToast', message)
        }
        return
      }

      router.push({ name: 'ProfilePay', query: { price: price.value } })
      store.dispatch('profile/handlePayModal', 'close')
    }

    // Handle Focus
    const inputNumber = ref(null)
    const focusOnInput = () => {
      inputNumber.value.$el.focus()
      nextTick(() => {
        window.scrollTo({ top: 300 })
      })
    }
    const selectPrice = (newPrice) => {
      price.value = newPrice
      focusOnInput()
    }

    watchEffect(() => {
      if (isOpen.value) setTimeout(focusOnInput, 100)
    })

    onBeforeUnmount(() => {
      price.value = ''
    })

    return { isOpen, price, submitForm, inputNumber, focusOnInput, selectPrice }
  }
}
</script>

<style lang="scss" scoped>
.pay {
  width: 100%;
  padding: 20px 0 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to top, #bbb 0, #fff 55px);
}
.pay__title {
  font-size: 18px;
  margin-bottom: 15px;
}
.pay__btns {
  margin: 15px 0 0;
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  gap: 10px;
}
.pay__btn {
  width: 100%;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background-color: #eee;
  color: #000;
  direction: ltr;
  border-radius: 10px;
  cursor: pointer;
  &::before {
    content: '+';
    width: 10px;
    height: 10px;
    margin-right: 3px;
  }
}

// change in common css
.credit-buttons {
  margin: 20px 0;
}
.button--pay {
  background-color: #eee;
  width: 90%;
  margin: 0;
}
</style>
