import { detect } from 'detect-browser'

export default {
  data() {
    return {
      appPermission: true,
      installEvt: null
    }
  },
  created() {
    // Detect browser
    const result = detect()
    const suppurtedBrowser =
      result.name == 'chrome' || result.name == 'ios' || result.name == 'safari'
    // Check for standalone mode to make sure app is in pwa mode
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches

    // If standalone give access to the app else add events
    if (isStandalone) this.appPermission = true
    else if (!suppurtedBrowser) this.appPermission = true
    else {
      // Save PWA install event
      window.addEventListener('beforeinstallprompt', ($event) => {
        this.installEvt = $event
        $event.preventDefault()
      })

      // Handle installing app
      window.addEventListener('appinstalled', () => {
        // Clear the install Evt
        this.installEvt = null
        // this.appPermission = true
        setTimeout(
          () =>
            this.$store.dispatch('addToast', {
              type: 'success',
              message: 'اپلیکیشن با موفقیت نصب گردید.'
            }),
          2000
        )
      })

      // Change Mode to stand alone (installing the app)
      window
        .matchMedia('(display-mode: standalone)')
        .addEventListener('change', ($event) => {
          if ($event.matches) {
            this.appPermission = true
          }
        })
    }
  },
  methods: {
    async handleInstall() {
      if (!this.installEvt) return

      this.installEvt.prompt()

      const result = await this.installEvt.userChoice
      console.log(result)
    }
  }
}
