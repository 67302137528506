import { createStore } from 'vuex'
import router from '@/router'
import * as CoreService from '@service/CoreService'
import * as auth from './modules/auth'
import * as plan from './modules/plan'
import * as offer from './modules/offer'
import * as gift from './modules/gift'
import * as customer from './modules/customer'
import * as profile from './modules/profile'
import * as product from './modules/product'
import * as reminder from './modules/reminder'
import * as notice from './modules/notice'
import * as survey from './modules/survey'
import * as lottery from './modules/lottery'
import * as event from './modules/event'
import * as ticket from './modules/ticket'
import * as receiver from './modules/receiver'
import * as bonus from './modules/bonus'
import * as footer from './modules/footer'
import * as comment from './modules/comment'

export default createStore({
  modules: {
    auth,
    plan,
    offer,
    gift,
    customer,
    profile,
    product,
    reminder,
    notice,
    survey,
    lottery,
    event,
    ticket,
    receiver,
    bonus,
    footer,
    comment
  },
  state: {
    stat: null,
    toasts: [],
    freshLogin: true,
    device: '',
    homeData: null,
    wallet: 0,
    accountExpiration: 0,
    routerHistory: [],
    routerSaveMode: true,
    noPackageMode: false,
    ticketCount: 0
  },
  mutations: {
    SET_STAT(state, data) {
      state.stat = data
    },
    CLEAR_STAT(state) {
      state.stat = null
    },
    PUSH_TOAST(state, toast) {
      // toast = { type, message }
      const id = Math.random()
        .toString(36)
        .substr(2)
      state.toasts.push({ ...toast, id })
    },
    REMOVE_TOAST(state, toast) {
      state.toasts = state.toasts.filter((item) => item.id !== toast.id)
    },
    CHANGE_FRESH_LOGIN(state, val) {
      state.freshLogin = val
    },
    SET_DEVICE(state, device) {
      state.device = device
    },
    SET_HOME_DATA(state, data) {
      state.homeData = data
    },
    SET_HISTORY(state, route) {
      state.routerHistory.push(route)
    },
    SAVE_MODE_ACTIVE(state) {
      state.routerSaveMode = true
    },
    SAVE_MODE_DEACTIVE(state) {
      state.routerSaveMode = false
    },
    SET_WALLET(state, wallet) {
      state.wallet = wallet
    },
    SET_EXPIRATION(state, date) {
      if (!date) return
      const deltaT = new Date(date) - new Date()

      state.accountExpiration =
        deltaT > 0 ? Math.floor(deltaT / (1000 * 60 * 60 * 24)) : 0
    },
    NO_PACKAGE_MODE_ACTIVE(state) {
      state.noPackageMode = true
    },
    NO_PACKAGE_MODE_DEACTIVE(state) {
      state.noPackageMode = false
    },
    SET_TICKET_COUNT(state, count) {
      state.ticketCount = count
    }
  },
  actions: {
    async fetchStat({ commit, dispatch }, { part }) {
      try {
        const { data } = await CoreService.getStat(part)
        commit('SET_STAT', data)
      } catch (ex) {
        if (ex.response) {
          dispatch('addToast', {
            type: 'error',
            error: 'اتصال به سرور مقدور نمی‌باشد.'
          })
        }
      }
    },
    clearStat({ commit }) {
      commit('CLEAR_STAT')
    },
    addToast({ commit }, toast) {
      const { type } = toast
      if (type === 'status') {
        return
      } else {
        commit('PUSH_TOAST', toast)
      }
    },
    removeToast({ commit }, toast) {
      commit('REMOVE_TOAST', toast)
    },
    displayErrors({ dispatch }, errors) {
      if (errors && errors.length > 0) {
        for (let error of errors) {
          dispatch('addToast', { type: 'error', message: error })
        }
      }
    },
    setDevice({ commit }, device) {
      commit('SET_DEVICE', device)
    },
    async fetchHomeData({ commit }) {
      try {
        const { data } = await CoreService.getHomeData()
        commit('SET_HOME_DATA', data)
        commit('SET_WALLET', data['wallet'])
        commit('SET_EXPIRATION', data['expire_at'])
        commit('SET_TICKET_COUNT', data['ticket_count'])
      } catch (ex) {
        console.log(ex)
      }
    },
    handleSaveRoutes({ commit }, route) {
      commit('SET_HISTORY', route)
    },
    goBack({ state, commit }) {
      const route = state.routerHistory.pop()
      commit('SAVE_MODE_DEACTIVE')
      router.replace(route)
    },
    handleNoPackage({ commit }, mode) {
      if (mode) {
        commit('NO_PACKAGE_MODE_ACTIVE')
      } else commit('NO_PACKAGE_MODE_DEACTIVE')
    }
  },
  getters: {
    isIphoneX: (state) => state.device === 'iphonex'
  }
})
