<template>
  <modal
    :title="title"
    type="center"
    :is-open="isOpen"
    @modalClosed="closeModal"
    height="160px"
  >
    <div class="modal-inside">
      <div class="credit-buttons">
        <a
          class="button button--black"
          v-if="type == 'package'"
          @click="handleEnterPackage"
        >
          <span class="button__text">فعالسازی بسته</span>
        </a>
        <button class="button button--black" v-else @click="handlePay">
          <span class="button__text">افزایش موجودی</span>
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'ProfileNoCreditModal',
  setup() {
    // Define main items
    const store = useStore()
    const router = useRouter()
    const isOpen = computed(() => store.state.profile.creditModal.isOpen)
    const type = computed(() => store.state.profile.creditModal.type)

    const title = computed(() => {
      if (type.value == 'package') return 'شما بسته اشتراک فعالی ندارید.'
      return 'موجودی حساب شما کافی نمی‌باشد.'
    })
    // Handle if user select packages
    const handleEnterPackage = () => {
      router.push({ name: 'TransactionPackage' })
      store.dispatch('profile/handleCreditModal', {
        status: 'close',
        type: 'package'
      })
    }

    // Handle user select increase credit
    const handlePay = () => {
      store.dispatch('profile/handleCreditModal', {
        status: 'close',
        type: 'credit'
      })
      store.dispatch('profile/handlePayModal', 'open')
    }

    // Handle close modal
    const closeModal = () => {
      store.dispatch('profile/handleCreditModal', 'close')
    }

    return { isOpen, handleEnterPackage, handlePay, closeModal, type, title }
  }
}
</script>

<style lang="scss" scoped>
.modal-inside .credit-buttons {
  justify-content: center;
  margin-top: 20px;
}
.button--black {
  width: 100%;
}
</style>
