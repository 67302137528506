const PlanNew = () =>
  import(/* webpackChunkName: "plan" */ '@view/store/credit-plan/PlanNew')
const PlanTransaction = () =>
  import(
    /* webpackChunkName: "plan" */ '@view/store/credit-plan/PlanTransaction'
  )
const PlanShow = () =>
  import(/* webpackChunkName: "plan" */ '@view/store/credit-plan/PlanShow')
const PlanStat = () =>
  import(/* webpackChunkName: "plan" */ '@view/store/credit-plan/PlanStat')

export default [
  {
    path: '/store/plan',
    name: 'Plan',
    component: () =>
      import(/* webpackChunkName: "plan" */ '@view/store/credit-plan/Plan'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'PlanHome',
        component: () =>
          import(
            /* webpackChunkName: "plan" */ '@view/store/credit-plan/PlanHome'
          )
      },
      {
        path: 'new',
        name: 'PlanNew',
        component: PlanNew
      },
      {
        path: 'stat',
        name: 'PlanStat',
        component: PlanStat
      }
    ]
  },
  {
    path: '/store/plan/:id',
    name: 'PlanShow',
    component: PlanShow,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/plan/transactions/:id',
    name: 'PlanTransaction',
    component: PlanTransaction,
    props: true,
    meta: { requiresAuth: true }
  }
]
