import * as commonItems from './commonItems'

export const namespaced = true

export const state = {
  itemsCount: 0,
  items: [],
  page: 1,
  pageStatus: '',
  item: null,
  isDark: false,
  surveyItemText: ''
}

export const mutations = {
  ...commonItems.mutations,
  SET_SURVEY_ITEM_TEXT(state, text) {
    state.surveyItemText = text
  }
}

export const actions = {
  ...commonItems.actions
}
