<template>
  <div
    :class="[
      'toast',
      toast.type === 'success' && 'toast--gold',
      toast.type === 'error' && 'toast--red'
    ]"
  >
    <i class="toast__side" />
    <p class="toast__item">
      {{ toast?.message }}
    </p>
    <span class="toast__close" @click="removeToast">&times;</span>
  </div>
</template>

<script>
import { onBeforeUnmount, ref } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'BaseToast',
  props: {
    toast: Object
  },
  setup(props) {
    const store = useStore()
    const timer = ref(null)
    const removeToast = () => {
      store.dispatch('removeToast', props.toast)
    }

    timer.value = setTimeout(
      () => store.dispatch('removeToast', props.toast),
      props.toast.type === 'success' ? 3000 : 3000
    )
    onBeforeUnmount(() => clearTimeout(timer.value))

    return { removeToast }
  }
}
</script>

<style lang="scss" scoped>
.toast {
  position: relative;
  margin: 6px auto;
  padding: 10px;
  max-width: 320px;
  color: #111;
  background-color: #fff;
  box-shadow: 0 0 10px 1px rgba(#000, 0.3);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.toast__side {
  height: 100;
  width: 3px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.toast--red .toast__side {
  background-color: #d40000;
}

.toast--gold .toast__side {
  background-color: #c69d4e;
}

.toast__text {
  font-size: 14px;
  margin: 0;
}

.toast__close {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  left: 10px;
  top: 54%;
  color: #777;
  transform: translateY(-50%);
}

.toast__item {
  font-size: 14px;
  line-height: 24px;
  padding-right: 3px;
  // &::before {
  //   content: '*';
  //   margin-left: 3px;
  // }
}
</style>
