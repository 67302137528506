import * as EventService from '@service/EventService'
import * as commonItems from './commonItems'

export const namespaced = true

export const state = {
  itemsCount: 0,
  items: [],
  page: 1,
  pageStatus: '',
  item: null,
  logs: []
}

export const mutations = {
  ...commonItems.mutations,
  SET_LOGS(state, logs) {
    state.logs = logs
  }
}

export const actions = {
  ...commonItems.actions,
  async fetchEventLogs({ commit }) {
    try {
      const { data } = await EventService.getEventLogs()
      commit('SET_LOGS', data.payments)
    } catch (ex) {
      console.log(ex)
    }
  }
}
