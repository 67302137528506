import * as commonItems from './commonItems'

export const namespaced = true

export const state = {
  itemsCount: 0,
  items: [],
  page: 1,
  pageStatus: '',
  item: null,
  lotteryOptions: null
}

export const mutations = {
  ...commonItems.mutations,
  SET_LOTTERY_OPTIONS(state, options) {
    state.lotteryOptions = options
  }
}

export const actions = {
  ...commonItems.actions,
  // for stat
  handleEnterLottery({ commit }, data) {
    commit('SET_LOTTERY_OPTIONS', data)
  }
}
