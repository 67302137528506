import * as commonItems from './commonItems'

export const namespaced = true

export const state = {
  itemsCount: 0,
  items: [],
  page: 1,
  pageStatus: '',
  item: null,
  isDark: false,
  sellPlans: [],
  backupItems: []
}

export const mutations = {
  ...commonItems.mutations,
  /** Loading */
  SET_STAT(state, stat) {
    state.stat = stat
  },
  SET_SELL_PLANS(state, plans) {
    state.sellPlans = plans
  }
}

export const actions = {
  ...commonItems.actions,
  setSellPlans({ commit }, plans) {
    commit('SET_SELL_PLANS', plans)
  }
}
