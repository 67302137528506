import http from './HttpService'
import qs from 'querystring'

export const getProvinces = () => {
  return http.get('/core/province/')
}

export const getCities = (id) => {
  const query = qs.stringify({ province: id })
  return http.get('/core/city/?' + query)
}

export const getBanners = () => {
  return http.get('/core/banner/')
}

export const getBanner = (id) => {
  return http.get('/core/banner/' + id + '/')
}

// Ready texts of the system
export const getSamples = () => {
  return http.get('/core/sample/')
}

export const getTransactions = (type, relationId) => {
  const query = qs.stringify({ type, relation_id: relationId })
  return http.get('/store/transaction/?' + query)
}

export const getStat = (part) => {
  return http.get('/store/' + part + '/stat/')
}

export const getHomeData = () => {
  return http.get('/store/')
}
