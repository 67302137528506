<script setup>
import { reactive, ref, computed, defineAsyncComponent } from 'vue'

const modals = reactive({
  search: false
})
const formHeight = ref('181px')
const currentPage = ref('footer-search-menu')
const currentComponent = computed(() => {
  switch (currentPage.value) {
    case 'footer-search-menu':
      return defineAsyncComponent(() => import('./FooterSearchMenu'))
    case 'footer-search-mobile':
      return defineAsyncComponent(() => import('./FooterSearchMobile'))
    case 'footer-search-name':
      return defineAsyncComponent(() => import('./FooterSearchName'))

    default:
      return defineAsyncComponent(() => import('./FooterSearchMenu'))
  }
})

const updateForm = (form) => {
  switch (form) {
    case 'menu':
      currentPage.value = 'footer-search-menu'
      formHeight.value = '181px'
      break

    case 'name':
      currentPage.value = 'footer-search-name'
      formHeight.value = '250px'
      break

    case 'mobile':
      currentPage.value = 'footer-search-mobile'
      formHeight.value = '254px'
      break

    default:
      break
  }
}

const refreshForm = () => {
  modals['search'] = false
  updateForm('menu')
  formHeight.value = '181px'
}
</script>

<template>
  <li class="footer__item" @click="modals['search'] = true">
    <a class="footer__link">
      <svg
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
        viewBox="0 0 512 512"
        class="footer__icon"
      >
        <path
          d="M489.4 508.14c5.14,5.14 13.6,5.15 18.74,0 5.15,-5.14 5.15,-13.6 0,-18.74l-135.98 -135.98 4.24 -5.12c16.92,-20.36 29.89,-43.69 38.19,-68.83 8.03,-24.31 11.58,-50.04 10.51,-75.62 -2.23,-53.42 -24.28,-103.75 -62.09,-141.57 -83.05,-83.04 -217.68,-83.04 -300.73,0 -83.04,83.05 -83.04,217.69 0,300.73 37.82,37.81 88.15,59.86 141.57,62.09 25.58,1.07 51.3,-2.48 75.62,-10.51 25.14,-8.3 48.46,-21.27 68.83,-38.19l5.11 -4.24 135.99 135.98zm-145.47 -426.78c72.51,72.51 72.51,190.07 0,262.57 -72.51,72.51 -190.06,72.51 -262.57,0 -72.5,-72.5 -72.5,-190.06 0,-262.57 72.51,-72.5 190.07,-72.5 262.57,0z"
        />
      </svg>
    </a>
  </li>
  <modal
    type="bottom"
    :is-open="modals['search']"
    @modalClosed="refreshForm"
    :height="formHeight"
  >
    <component
      :is="currentComponent"
      @changeForm="updateForm"
      @done="refreshForm"
    />
  </modal>
</template>
