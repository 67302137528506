import moment from 'jalali-moment'
// Calculate expiration time based on how many days remaining
// declare global {
//   interface Date {
//     addDays(days: number): Date
//   }
// }

// export const calcExpireTime = (days: number): string => {
//   Date.prototype.addDays = function(days): Date {
//     let date = new Date(this.valueOf())
//     date.setDate(date.getDate() + days)
//     return date
//   }

//   const newDate = new Date().addDays(days)

//   return `${newDate.getFullYear()}-${newDate.getMonth() +
//     1}-${newDate.getDate()}`
// }
export const calcExpireTime = (days) => {
  Date.prototype.addDays = function(days) {
    let date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }

  const newDate = new Date().addDays(days)

  return `${newDate.getFullYear()}-${newDate.getMonth() +
    1}-${newDate.getDate()}`
}

const addZero = (n) => {
  return +n >= 10 ? n : '0' + n
}

export const localizeDate = (date, fourDigit = false) => {
  const jsDate = new Date(date)
  const formattedDate = jsDate
    .toLocaleDateString('fa-IR')
    .replace(/([۰-۹])/g, (token) =>
      String.fromCharCode(token.charCodeAt(0) - 1728)
    )
  const [, year, month, day] = /(\d{4})\/(\d{1,2})\/(\d{1,2})/.exec(
    formattedDate
  )
  if (fourDigit) {
    return `${addZero(month)}${addZero(day)}`
  }

  return `${year}/${addZero(month)}/${addZero(day)}`
}

const jalaliMonths = [
  { turn: '01', name: 'فروردین' },
  { turn: '02', name: 'اردیبهشت' },
  { turn: '03', name: 'خرداد' },
  { turn: '04', name: 'تیر' },
  { turn: '05', name: 'مرداد' },
  { turn: '06', name: 'شهریور' },
  { turn: '07', name: 'مهر' },
  { turn: '08', name: 'آبان' },
  { turn: '09', name: 'آذر' },
  { turn: '10', name: 'دی' },
  { turn: '11', name: 'بهمن' },
  { turn: '12', name: 'اسفند' }
]

export const jalaliToMiladi = ({ day, month, year }) => {
  const _day = addZero(day)
  const _month = jalaliMonths.find((item) => item.name === month)['turn']
  const _year = year

  const m = moment(`${_year}/${_month}/${_day}`, 'jYYYY/jM/jD')
  return m.format('YYYY-MM-DD')
}

export const applyTimeZone = (date) => {
  const jsDate = new Date(date)
  const tehranOffset = jsDate.getTimezoneOffset()
  // Make it standard
  jsDate.setHours(jsDate.getHours() - Math.floor(-tehranOffset / 60))
  jsDate.setMinutes(jsDate.getMinutes() - (-tehranOffset % 60))

  return jsDate
}
