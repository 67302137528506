import http from './HttpService'
import qs from 'querystring'

export const getTickets = (page, perPage) => {
  const items = {
    limit: perPage,
    offset: (page - 1) * perPage
  }

  const query = qs.stringify(items)
  return http.get('/store/ticket/?' + query)
}

export const getTicketInfo = (id) => {
  return http.get('/store/ticket/' + id + '/')
}

export const sendTicket = (id, data) => {
  const formData = new FormData()

  for (let key of Object.keys(data)) formData.append(key, data[key])

  return http.put('/store/ticket/' + id + '/', formData)
}

export const getTicketItems = (id, page, perPage) => {
  const query = qs.stringify({
    ticket_id: id,
    limit: perPage,
    offset: (page - 1) * perPage
  })
  return http.get('/store/ticketItem/?' + query)
}

export const blockTicket = (id) => {
  return http.get('/store/ticket/' + id + '/block/')
}

export const createTicket = (id) => {
  return http.get('/store/customer/' + id + '/createTicket/')
}

export const addSupportTicket = ({ department, title, message }) => {
  const Ticket = {
    DEPARTMENT: 'department',
    TITLE: 'title',
    MESSAGE: 'text'
  }

  const formData = new FormData()
  formData.append(Ticket.DEPARTMENT, department['id'])
  formData.append(Ticket.TITLE, title)
  formData.append(Ticket.MESSAGE, message)

  return http.post('/core/support/', formData)
}

export const getSupportTicket = () => {
  return http.get('/core/support/')
}
