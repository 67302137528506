const Home = () =>
  import(/* webpackChunkName: "home" */ '@view/store/home/Home')
const HomeStar = () =>
  import(/* webpackChunkName: "home" */ '@view/store/home/HomeStar')

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/home/star',
    name: 'HomeStar',
    component: HomeStar,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/home/stat',
    name: 'HomeStats',
    component: () =>
      import(/* webpackChunkName: "home" */ '@view/store/home/HomeStats'),
    meta: { requiresAuth: true }
  },
  {
    path: '/store/home/label',
    name: 'HomeLabel',
    component: () =>
      import(/* webpackChunkName: "home" */ '@view/store/home/HomeLabel'),
    meta: { requiresAuth: true }
  }
]
