import * as GiftService from '@service/GiftService'
import * as commonItems from './commonItems'

export const namespaced = true

export const state = {
  itemsCount: 0,
  items: [],
  backupItems: [],
  page: 1,
  pageStatus: '',
  item: null,
  isDark: false,
  giftItemsCount: {
    '1': 0,
    '2': 0,
    '3': 0
  },
  //-----------//
  organs: [],
  modalLoading: false,
  lastPageIsNeeded: false,
  pageLoading: false
}

export const mutations = {
  // ...commonMutations,
  ...commonItems.mutations,
  SET_GIFT_ITEMS_COUNT(state, { status, n }) {
    state.giftItemsCount[status] = n
  },
  RESET_GIFT_ITEMS_COUNT(state) {
    state.giftItemsCount = {
      '1': 0,
      '2': 0,
      '3': 0
    }
  },
  SET_ORGANS(state, data) {
    state.organs = data
  },
  START_MODAL_LOADING(state) {
    state.modalLoading = true
  },
  STOP_MODAL_LOADING(state) {
    state.modalLoading = false
  },
  // -------------- //
  LAST_PAGE_IS_NEEDED_ON(state) {
    state.lastPageIsNeeded = true
  },
  LAST_PAGE_IS_NEEDED_OFF(state) {
    state.lastPageIsNeeded = false
  }
}

export const actions = {
  ...commonItems.actions,
  async fetchGiftItemsCount({ commit }, id) {
    try {
      const results = await GiftService.getAllGiftItems(id)
      for (let res in results) {
        const status = +res + 1
        commit('SET_GIFT_ITEMS_COUNT', {
          status,
          n: results[res].data.count
        })
      }
    } catch (ex) {
      console.log(ex)
    }
  },
  async fetchOrgans({ commit }) {
    commit('START_MODAL_LOADING')
    try {
      const { data } = await GiftService.getOrgans()
      commit('SET_ORGANS', data.results)
      commit('STOP_MODAL_LOADING')
    } catch (ex) {
      commit('STOP_MODAL_LOADING')
    }
  }
}
