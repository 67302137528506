import http from '@service/HttpService'
import qs from 'querystring'

export const getItems = (name, query) => {
  const queryString = qs.stringify({
    ...query
  })

  return http.get('/store/' + name + '/?' + queryString)
}

export const getItem = (name, id) => {
  return http.get('/store/' + name + '/' + id + '/')
}

export const getRecords = ({ url, query }) => {
  const queryString = qs.stringify({ ...query })
  return http.get(url + '?' + queryString)
}
