import * as BonusService from '@service/BonusService'
import * as commonItems from './commonItems'
import router from '@/router'

export const namespaced = true

export const state = {
  itemsCount: 0,
  items: [],
  page: 1,
  pageStatus: '',
  item: null,
  isDark: false,
  backupItems: [],
  bonusItemsCount: {
    '1': 0,
    '2': 0,
    '3': 0
  },
  bonusOptions: null
}

export const mutations = {
  ...commonItems.mutations,
  SET_BONUS_ITEMS_COUNT(state, { status, n }) {
    state.bonusItemsCount[status] = n
  },
  RESET_BONUS_ITEMS_COUNT(state) {
    const { bonusItemsCount: counts } = state
    for (let key of Object.keys(counts)) counts[key] = 0
  },
  SET_BONUS_OPTIONS(state, options) {
    state.bonusOptions = options
  }
}

export const actions = {
  ...commonItems.actions,
  async fetchBonusItemsCount({ commit }, id) {
    try {
      const results = await BonusService.getAllBonusItems(id)
      for (let res in results) {
        const status = +res + 1
        commit('SET_BONUS_ITEMS_COUNT', {
          status,
          n: results[res].data.count
        })
      }
    } catch (ex) {
      console.log(ex)
    }
  },
  handleEnterBonus({ commit }, options) {
    commit('SET_BONUS_OPTIONS', options)
    router.push({ name: 'BonusNew' })
  }
}
