export default [
  {
    path: '/store/ticket',
    name: 'Ticket',
    component: () =>
      import(/* webpackChunkName: "ticket" */ '@view/store/chat/Ticket'),
    meta: { requiresAuth: true }
  },
  {
    path: '/store/ticket/:id',
    name: 'TicketShow',
    component: () =>
      import(/* webpackChunkName: "ticket" */ '@view/store/chat/TicketShow'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/support',
    name: 'TicketSupport',
    component: () =>
      import(/* webpackChunkName: "ticket" */ '@view/store/chat/TicketSupport'),
    meta: { requiresAuth: true }
  },
  {
    path: '/store/support/new',
    name: 'TicketSupportNew',
    component: () =>
      import(
        /* webpackChunkName: "ticket" */ '@view/store/chat/TicketSupportNew'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/store/support/:id',
    name: 'TicketSupportShow',
    component: () =>
      import(
        /* webpackChunkName: "ticket" */ '@view/store/chat/TicketSupportShow'
      ),
    props: true,
    meta: { requiresAuth: true }
  }
]
