const Survey = () =>
  import(/* webpackChunkName: "survey" */ '@view/store/vote/Survey')
const SurveyHome = () =>
  import(/* webpackChunkName: "survey" */ '@view/store/vote/SurveyHome')
const SurveyNew = () =>
  import(/* webpackChunkName: "survey" */ '@view/store/vote/SurveyNew')
const SurveyStat = () =>
  import(/* webpackChunkName: "survey" */ '@view/store/vote/SurveyStat')
const SurveyShow = () =>
  import(/* webpackChunkName: "survey" */ '@view/store/vote/SurveyShow')
const SurveyComments = () =>
  import(/* webpackChunkName: "survey" */ '@view/store/vote/SurveyComments')
const SurveyCustomer = () =>
  import(/* webpackChunkName: "survey" */ '@view/store/vote/SurveyCustomer')
const CustomerFilter = () =>
  import(/* webpackChunkName: "survey" */ '@view/store/customer/CustomerFilter')

export default [
  {
    path: '/store/survey',
    name: 'Survey',
    component: Survey,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'SurveyHome',
        component: SurveyHome
      },
      {
        path: 'new',
        name: 'SurveyNew',
        component: SurveyNew
      },
      {
        path: 'stat',
        name: 'SurveyStat',
        component: SurveyStat
      },
      {
        path: 'filter/:destination',
        name: 'SurveyCustomerFilter',
        component: CustomerFilter,
        props: true
      }
    ]
  },
  {
    path: '/store/survey/:id',
    name: 'SurveyShow',
    component: SurveyShow,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //#TODO Fix in backend and check for ios
    path: '/store/survey/:id/comment',
    name: 'SurveyComments',
    component: SurveyComments,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    //#TODO Fix in backend and check for ios
    path: '/store/survey/:id/vote',
    name: 'SurveyCustomer',
    component: SurveyCustomer,
    props: true,
    meta: { requiresAuth: true }
  }
]
