import http from './HttpService'
import qs from 'querystring'

export const getReminders = (page, perPage) => {
  const query = qs.stringify({
    limit: perPage,
    offset: (page - 1) * perPage
  })

  return http.get('/store/reminder/?' + query)
}

export const getReminder = (id) => {
  return http.get('/store/reminder/' + id + '/')
}

export const addReminder = ({ message, lastBuy, hasName }) => {
  const items = {
    message: message ?? '-',
    last_buy: lastBuy,
    include_name: hasName
  }

  const formData = new FormData()
  for (let key of Object.keys(items)) {
    formData.append(key, items[key])
  }

  return http.post('/store/reminder/', formData)
}

export const editReminder = (id, { message, lastBuy, hasName }) => {
  const items = {
    message: message ?? '-',
    last_buy: lastBuy,
    include_name: hasName
  }

  const formData = new FormData()
  for (let key of Object.keys(items)) {
    formData.append(key, items[key])
  }

  return http.put('/store/reminder/' + id + '/', formData)
}

export const deleteReminder = (id) => {
  return http.delete('/store/reminder/' + id + '/')
}

export const getReminderLogs = () => {
  return http.get('/store/reminder/log/')
}
