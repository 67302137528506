import * as OfferService from '@service/OfferService'
// import commonMutations from './commonMutations'
import * as commonItems from './commonItems'
import router from '@/router'

export const namespaced = true

export const state = {
  itemsCount: 0,
  items: [],
  page: 1,
  pageStatus: '',
  item: null,
  isDark: false,
  backupItems: [],
  offerItemsCount: {
    '1': 0,
    '2': 0,
    '3': 0
  },
  offerOptions: null
}
export const mutations = {
  // ...commonMutations,
  ...commonItems.mutations,
  SET_OFFER_ITEMS_COUNT(state, { status, n }) {
    state.offerItemsCount[status] = n
  },
  RESET_OFFER_ITEMS_COUNT(state) {
    const { offerItemsCount: counts } = state
    for (let key of Object.keys(counts)) counts[key] = 0
  },
  SET_OFFER_OPTIONS(state, options) {
    state.offerOptions = options
  }
}
export const actions = {
  ...commonItems.actions,
  async fetchOfferItemsCount({ commit }, id) {
    try {
      const results = await OfferService.getAllOfferItems(id)
      for (let res in results) {
        const status = +res + 1
        commit('SET_OFFER_ITEMS_COUNT', {
          status,
          n: results[res].data.count
        })
      }
    } catch (ex) {
      console.log(ex)
    }
  },
  handleEnterOffer({ commit }, options) {
    commit('SET_OFFER_OPTIONS', options)
    router.push({ name: 'OfferNew' })
  }
}
