import http from './HttpService'
import qs from 'querystring'
import { calcExpireTime } from '@util/Time'

export const getOffers = (page, perPage) => {
  const query = qs.stringify({
    limit: perPage,
    offset: (page - 1) * perPage
  })

  return http.get('/store/offer/?' + query)
}

export const getOffer = (id) => {
  return http.get('/store/offer/' + id + '/')
}

export const disableOffer = (id) => {
  return http.get('/store/offer/' + id + '/disable/')
}

export const getOfferItems = (id, status, page) => {
  const query = qs.stringify({
    offer: id,
    status,
    page
  })
  return http.get('/store/offerItem/?' + query)
}

export const getAllOfferItems = (id) => {
  const activeApiCall = getOfferItems(id, '1', 1)
  const usedApiCall = getOfferItems(id, '2', 1)
  const expiredApiCall = getOfferItems(id, '3', 1)

  return Promise.all([activeApiCall, usedApiCall, expiredApiCall])
}

export const addOffer = ({
  offerName,
  offerDesc,
  offerPrice,
  offerPriceNew,
  offerTime,
  offerImage,
  filters
}) => {
  const formData = new FormData()
  formData.append('title', offerName)
  formData.append('desc', offerDesc)
  formData.append('image', offerImage)
  formData.append('price', offerPrice)
  formData.append('price_final', offerPriceNew)
  formData.append('expire_at', calcExpireTime(offerTime))

  // Add filters
  for (let key of Object.keys(filters)) {
    formData.append(key, filters[key])
  }

  return http.post('/store/offer/', formData)
}

export const getOfferItem = (id) => {
  return http.get('/store/offerItem/' + id + '/')
}

// single offer
export const addSingleOffer = ({
  offerName,
  offerDesc,
  offerPrice,
  offerPriceNew,
  offerTime,
  offerImage,
  offerWallet
}) => {
  const formData = new FormData()
  formData.append('title', offerName)
  formData.append('desc', offerDesc)
  formData.append('image', offerImage)
  formData.append('price', offerPrice)
  formData.append('price_final', offerPriceNew)
  formData.append('expire_at', calcExpireTime(offerTime))
  formData.append('wallet_id', offerWallet)

  return http.post('/store/offer/single/', formData)
}
