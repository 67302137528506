import * as TicketService from '@service/TicketService'
import * as ItemService from '@service/ItemService'
import commonMutations from './commonMutations'
import * as commonItems from './commonItems'

export const namespaced = true

export const state = {
  items: [],
  pageStatus: '',
  page: 1,
  item: null,
  darkMode: false,
  isLoading: false,
  btnLoading: false,
  pageLoading: false,
  perPage: 10,
  ticket: null,
  messageText: '',
  messageImage: null,
  messages: [],
  totalMessages: null,
  messagePage: 1,
  messagePerPage: 10
}

export const mutations = {
  ...commonMutations,
  ...commonItems.mutations,
  SET_TICKETS(state, tickets) {
    state.tickets = [...state.tickets, ...tickets]
  },
  CLEAR_TICKETS(state) {
    state.tickets = []
  },
  SET_TOTAL_TICKETS(state, n) {
    state.totalTickets = n
  },
  SET_TICKET(state, data) {
    state.ticket = data
  },
  SET_PREVIOUS_MESSAGES(state, data) {
    state.messages = [...data, ...state.messages]
  },
  SET_TOTAL_MESSAGES(state, n) {
    state.totalMessages = n
  },
  CLEAR_MESSAGES(state) {
    state.messages = []
  },
  RESET_MESSAGES_PAGE(state) {
    state.messagePage = 1
  },
  SET_MESSAGES_LAST_PAGE(state) {
    state.messagePage = -1
  },
  INCREMENT_MESSAGES_PAGE(state) {
    state.messagePage++
  },
  PUSH_MESSAGE(state, msg) {
    state.messages.push(msg)
  },
  UPDATE_MESSAGE_TEXT(state, value) {
    state.messageText = value
  },
  UPDATE_MESSAGE_IMAGE(state, value) {
    state.messageImage = value
  }
}

export const actions = {
  ...commonItems.actions,
  async fetchMessages({ state, commit }, { id }) {
    const { messagePage: page } = state
    if (page === -1) return commit('SET_MESSAGES_LAST_PAGE')

    const url = '/store/ticketItem/'
    const query = { page, ticket_id: id }

    if (page === 1) commit('START_PAGE_LOADING')
    commit('START_LOADING')
    try {
      const { data } = await ItemService.getRecords({ url, query })
      const reversedMsgs = data.results.reverse()
      commit('SET_TOTAL_MESSAGES', data['count'])
      commit('SET_PREVIOUS_MESSAGES', reversedMsgs)

      if (!data['next']) commit('SET_MESSAGES_LAST_PAGE')
      else commit('INCREMENT_MESSAGES_PAGE')
    } catch (ex) {
      console.log(ex)
    } finally {
      commit('STOP_PAGE_LOADING')
      commit('STOP_LOADING')
    }
  },
  clearTickets({ commit }) {
    commit('CLEAR_TICKETS')
    commit('RESET_PAGE')
    commit('STOP_LOADING')
    commit('STOP_PAGE_LOADING')
  },
  async sendMessage(
    { state, commit, dispatch },
    { ticketId, customerId, StoreId }
  ) {
    const messageToShow = {
      id: Math.floor(Math.random() * 65535).toString(16),
      text: state.messageText,
      image: null,
      status: 'loading',
      response_type: '3',
      created_at: new Date()
    }
    const messageToSend = {
      text: state.messageText,
      store: StoreId,
      wallet: customerId
    }
    // Clear message in state
    commit('UPDATE_MESSAGE_TEXT', '')
    // Add message to the DOM
    commit('PUSH_MESSAGE', messageToShow)
    // Send for backend
    try {
      await TicketService.sendTicket(ticketId, messageToSend)
      const idx = state.messages.indexOf(messageToShow)
      state.messages[idx]['status'] = 'resolve'
    } catch (ex) {
      if (ex.response) {
        switch (ex.response.status) {
          default:
            dispatch('addToast', {
              type: 'error',
              message: 'ارسال پیام ناموفق بود.'
            })
        }
      }
      const idx = state.messages.indexOf(messageToShow)
      state.messages[idx]['status'] = 'reject'
    }
  },
  async sendImage(
    { state, commit, dispatch },
    { ticketId, customerId, StoreId }
  ) {
    const messageToShow = {
      id: Math.floor(Math.random() * 65535).toString(16),
      text: null,
      image: state.messageImage['base64'],
      status: 'loading',
      response_type: '3',
      created_at: new Date()
    }
    const messageToSend = {
      image: state.messageImage['file'],
      store: StoreId,
      wallet: customerId
    }
    // Clear image in state
    commit('UPDATE_MESSAGE_IMAGE', null)
    // Add message to the DOM
    commit('PUSH_MESSAGE', messageToShow)
    // Send for backend
    try {
      await TicketService.sendTicket(ticketId, messageToSend)
      const idx = state.messages.indexOf(messageToShow)
      state.messages[idx]['status'] = 'resolve'
    } catch (ex) {
      if (ex.response) {
        switch (ex.response.status) {
          default:
            dispatch('addToast', {
              type: 'error',
              message: 'ارسال تصویر ناموفق بود.'
            })
        }
      }
      const idx = state.messages.indexOf(messageToShow)
      state.messages[idx]['status'] = 'reject'
    }
  },
  clearMessages({ commit }) {
    commit('STOP_PAGE_LOADING')
    commit('STOP_LOADING')
    commit('CLEAR_MESSAGES')
    commit('RESET_MESSAGES_PAGE')
  }
}
