import { getTempData } from '@service/TokenService'

const Profile = () =>
  import(/* webpackChunkName: "profile" */ '@view/store/profile/Profile')
const ProfileEdit = () =>
  import(/* webpackChunkName: "profile" */ '@view/store/profile/ProfileEdit')
const ProfileBranches = () =>
  import(
    /* webpackChunkName: "profile" */ '@view/store/profile/ProfileBranches'
  )
const ProfileSetting = () =>
  import(/* webpackChunkName: "profile" */ '@view/store/profile/ProfileSetting')
const ProfileSocial = () =>
  import(/* webpackChunkName: "profile" */ '@view/store/profile/ProfileSocial')
const ProfileSignup = () =>
  import(/* webpackChunkName: "profile" */ '@view/store/profile/ProfileSignup')
const ProfileBranchNew = () =>
  import(
    /* webpackChunkName: "profile" */ '@view/store/profile/ProfileBranchNew'
  )

const ProfilePay = () =>
  import(/* webpackChunkName: "profile" */ '@view/store/profile/ProfilePay')

export default [
  {
    path: '/store/profile',
    name: 'Profile',
    component: Profile,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'ProfileEdit',
        component: ProfileEdit
      },
      {
        path: 'branch',
        name: 'ProfileBranches',
        component: ProfileBranches
      },
      {
        path: 'social',
        name: 'ProfileSocial',
        component: ProfileSocial
      },
      {
        path: 'setting',
        name: 'ProfileSetting',
        component: ProfileSetting
      }
    ]
  },
  {
    path: '/store/pay',
    name: 'ProfilePay',
    component: ProfilePay,
    meta: { requiresAuth: true }
  },
  {
    // id: optional (if mode is edit)
    // mode: new || edit
    path: '/store/profile/branch/:id?/:mode',
    name: 'ProfileBranchNew',
    component: ProfileBranchNew,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/confirm',
    name: 'Signup',
    component: ProfileSignup,
    props: true,
    meta: { requiresAuth: true },
    beforeEnter: (_, from) => {
      if (from.name == 'Home') return false
    }
  },
  {
    path: '/store/stores',
    name: 'ProfileStore',
    component: () =>
      import(
        /* webpackChunkName: 'profile' */ '@view/store/profile/ProfileStore'
      )
    // beforeEnter: (to, from, next) => {
    // const tempData = getTempData()
    // if (!tempData) next({ name: 'Login' })
    // }
  }
]
