const Learn = () =>
  import(/* webpackChunkName: "other" */ '@view/store/learn/Learn')

export default [
  {
    path: '/store/learn',
    name: 'Learn',
    component: Learn,
    meta: { requiresAuth: true }
  }
]
