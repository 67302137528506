import * as CustomerService from '@service/CustomerService'
import * as commonItems from './commonItems'

export const namespaced = true

export const state = {
  items: [],
  itemsCount: 0,
  pageStatus: '',
  page: 1,
  // ........... //
  groups: null,
  labels: null,
  cities: { cities: null, count: 1 },
  filterData: {},
  filteredCusotmerCount: 0,
  filterIsFilled: false,
  customersInfo: null,
  pageLoading: false
}

export const mutations = {
  ...commonItems.mutations,
  SET_GROUPS(state, data) {
    state.groups = data
  },
  SET_FILTER_DATA(state, data) {
    state.filterData = data
  },
  SET_FILTERED_CUSTOMER_COUNT(state, n) {
    state.filteredCusotmerCount = n
  },
  SET_FILTER_FILLED(state) {
    state.filterIsFilled = true
  },
  SET_FILTER_UNFILLED(state) {
    state.filterIsFilled = false
  },
  SET_CUSTOMERS_INFO(state, info) {
    state.customersInfo = info
  },
  SET_LABELS(state, labels) {
    state.labels = labels
  },
  SET_CITIES(state, { cities, count }) {
    state.cities['cities'] = cities
    state.cities['count'] = count === 0 ? 1 : count
  },
  START_BTN_LOADING(state) {
    state.btnLoading = true
  },
  STOP_BTN_LOADING(state) {
    state.btnLoading = false
  }
}

export const actions = {
  ...commonItems.actions,
  async fetchCustomerGroups({ commit }) {
    try {
      const { data } = await CustomerService.getCustomerGroups()
      commit('SET_GROUPS', data.results)
    } catch (ex) {
      console.log(ex)
    }
  },
  async fetchCustomerLabels({ commit }) {
    try {
      const { data } = await CustomerService.getCustomerLabels()
      commit('SET_LABELS', data.results)
    } catch (ex) {
      console.log(ex)
    }
  },
  async fetchCustomerCities({ commit }) {
    try {
      const { data } = await CustomerService.getCustomerCities()
      commit('SET_CITIES', { cities: data.city, count: data.count })
    } catch (ex) {
      console.log(ex)
    }
  },
  async applyFilteredCustomers({ commit }, { form }) {
    commit('START_BTN_LOADING')
    commit('SET_FILTER_DATA', form)
    commit('SET_FILTER_FILLED')
    try {
      const { data } = await CustomerService.getFilteredCustomers(form)
      commit('SET_FILTERED_CUSTOMER_COUNT', data.count)
      commit('STOP_BTN_LOADING')
    } catch (ex) {
      console.log(ex)
      commit('STOP_BTN_LOADING')
    }
  },
  async fetchCustomersInfo({ commit }) {
    commit('SET_PAGE_STATUS', 'loading')
    try {
      const { data } = await CustomerService.getCustomersInfo()
      commit('SET_CUSTOMERS_INFO', data)
      commit('SET_PAGE_STATUS', 'resolved')
    } catch (ex) {
      commit('SET_PAGE_STATUS', 'failed')
    }
  },
  clearCustomersInfo({ commit }) {
    commit('SET_PAGE_STATUS', '')
    commit('SET_CUSTOMERS_INFO', null)
    commit('SET_LABELS', null)
  },
  clearCustomerCities({ commit }) {
    commit('SET_CITIES', { cities: null, count: 1 })
  },
  setCustomers({ commit }, data) {
    commit('ADD_ITEMS', data)

    if (data.length <= 10) commit('LAST_PAGE')
    else commit('INCREMENT_PAGE')

    commit('SET_PAGE_STATUS', 'resolved')
  }
}
