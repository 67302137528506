const Lottery = () =>
  import(/* webpackChunkName: "lottery" */ '@view/store/lottery/Lottery')
const LotteryHome = () =>
  import(/* webpackChunkName: "lottery" */ '@view/store/lottery/LotteryHome')
const LotteryStat = () =>
  import(/* webpackChunkName: "lottery" */ '@view/store/lottery/LotteryStat')
const LotteryNew = () =>
  import(/* webpackChunkName: "lottery" */ '@view/store/lottery/LotteryNew')
const LotteryWinner = () =>
  import(/* webpackChunkName: "lottery" */ '@view/store/lottery/LotteryWinner')
const CustomerFilter = () =>
  import(
    /* webpackChunkName: "lottery" */ '@view/store/customer/CustomerFilter'
  )

export default [
  {
    path: '/store/lottery',
    name: 'Lottery',
    component: Lottery,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'LotteryHome',
        component: LotteryHome,
        props: true
      },
      {
        path: 'stat',
        name: 'LotteryStat',
        component: LotteryStat,
        props: true
      },
      {
        path: 'new',
        name: 'LotteryNew',
        component: LotteryNew
      },
      {
        path: 'filter/:destination',
        name: 'LotteryCustomerFilter',
        component: CustomerFilter,
        props: true
      }
    ]
  },
  {
    path: '/store/lottery/:id',
    name: 'LotteryWinner',
    component: LotteryWinner,
    props: true,
    meta: { requiresAuth: true }
  }
]
