import http from './HttpService'
import qs from 'querystring'

export const disableGift = (id) => {
  return http.get('/store/bon/' + id + '/disable/')
}

export const getGiftItems = (id, status, page) => {
  const query = qs.stringify({
    bon: id,
    status,
    page
  })
  return http.get('/store/bonItem/?' + query)
}

export const getAllGiftItems = (id) => {
  const activeApiCall = getGiftItems(id, '1', 1)
  const usedApiCall = getGiftItems(id, '2', 1)
  const expireApiCall = getGiftItems(id, '3', 1)
  return Promise.all([activeApiCall, usedApiCall, expireApiCall])
}

export const disableGiftItem = (id) => {
  return http.get('/store/bonItem/' + id + '/disable/')
}

export const getOrgans = () => {
  return http.get('/store/organisation/')
}

export const addOrgan = (formData) => {
  return http.post('/store/organisation/', formData)
}

export const editOrgan = (id, formData) => {
  return http.patch('/store/organisation/' + id + '/', formData)
}

export const deleteOrgan = (id) => {
  return http.delete('/store/organisation/' + id + '/')
}

export const addGift = (gifts, items) => {
  const data = {}

  for (let gift of [1, 2, 3, 4, 5]) {
    if (gifts[gift - 1]) {
      data[`count[${gift}]`] = gifts[gift - 1]['giftCount']
      data[`price[${gift}]`] = gifts[gift - 1]['giftPrice']
    }
  }

  data['organization'] = String(items['organ'])
  // set time
  data['expire_at'] = items['expire']
  data['desc'] = items['explanation']

  const formData = new FormData()
  for (let key of Object.keys(data)) {
    formData.append(key, data[key])
  }
  return http.post('/store/bon/', formData)
}
