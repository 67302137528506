const Bonus = () =>
  import(/* webpackChunkName: "bonus" */ '@view/store/bonus/Bonus')
const BonusHome = () =>
  import(/* webpackChunkName: "bonus" */ '@view/store/bonus/BonusHome')
const BonusStat = () =>
  import(/* webpackChunkName: "bonus" */ '@view/store/bonus/BonusStat')
const BonusStatus = () =>
  import(/* webpackChunkName: "bonus" */ '@view/store/bonus/BonusStatus')
const BonusInquiry = () =>
  import(/* webpackChunkName: "bonus" */ '@view/store/bonus/BonusInquiry')
const BonusNew = () =>
  import(/* webpackChunkName: "bonus" */ '@view/store/bonus/BonusNew')
const BonusShow = () =>
  import(/* webpackChunkName: "bonus" */ '@view/store/bonus/BonusShow')
const CustomerFilter = () =>
  import(/* webpackChunkName: "bonus" */ '@view/store/customer/CustomerFilter')

export default [
  {
    path: '/store/bonus',
    name: 'Bonus',
    component: Bonus,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'BonusHome',
        component: BonusHome
      },
      {
        path: 'filter/:destination',
        name: 'BonusCustomerFilter',
        component: CustomerFilter,
        props: true
      },
      {
        path: 'new',
        name: 'BonusNew',
        component: BonusNew
      },
      {
        path: 'stat',
        name: 'BonusStat',
        component: BonusStat
      }
    ]
  },
  {
    path: '/store/bonus/:id',
    name: 'BonusShow',
    component: BonusShow,
    props: true
  },
  {
    path: '/store/bonus/:id/:status',
    name: 'BonusStatus',
    component: BonusStatus,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/sell/bonus/:mobile',
    name: 'BonusInquiry',
    component: BonusInquiry,
    props: true,
    meta: { requiresAuth: true }
  }
]
