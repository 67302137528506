import * as TokenService from '@service/TokenService'
import router from '@/router'
export const state = {
  tokens: null,
  username: '',
  password: '',
  code: ''
}

export const mutations = {
  SET_AUTH_DATA(state, auth) {
    state.tokens = auth
    TokenService.setToken(auth)
  },
  CLEAR_USER_DATA(state) {
    state.tokens = null
    state.username = ''
    state.password = ''
    state.code = ''
    TokenService.clearToken()
  },
  UPDATE_FORM_ITEMS(state, { field, value }) {
    state[field] = value
  }
}

export const actions = {
  loginUser({ commit }, auth) {
    commit('SET_AUTH_DATA', auth)
  },
  logoutUser({ commit, dispatch }) {
    commit('CLEAR_USER_DATA')
    commit('SET_HOME_DATA', null)
    dispatch('handleNoPackage', false)
    router.push({ name: 'Login' })
  }
}

export const getters = {
  loggedIn(state) {
    return !!state.user
  }
}
