import qs from 'querystring'
import http from './HttpService'

export const getBranch = (id) => {
  return http.get('/store/branch/' + id + '/')
}

export const deleteBranch = (id) => {
  return http.delete('/store/branch/' + id + '/')
}

export const createBranch = (items) => {
  const info = {
    title: items['branchName'],
    field: items['branchActivity'],
    image: items['branchImg'],
    address: items['branchAddress'],
    lat: items['branchLat'],
    lng: items['branchLng'],
    province: items['branchProvince']['id'],
    city: items['branchCity']['id'],
    tel: items['branchPhone'],
    desc: items['branchExp']
  }
  const formData = new FormData()
  for (let key of Object.keys(info)) {
    formData.append(key, info[key])
  }
  return http.post('/store/branch/', formData)
}

export const editBranch = (id, items) => {
  const info = {
    title: items['branchName'],
    field: items['branchActivity'],
    address: items['branchAddress'],
    lat: items['branchLat'],
    lng: items['branchLng'],
    province: items['branchProvince']['id'],
    city: items['branchCity']['id'],
    tel: items['branchPhone'],
    desc: items['branchExp']
  }

  if (items['branchImg']) {
    info['image'] = items['branchImg']
  }

  const formData = new FormData()
  for (let key of Object.keys(info)) {
    formData.append(key, info[key])
  }
  return http.put('/store/branch/' + id + '/', formData)
}

export const getProfile = () => {
  return http.get('/store/profile/')
}

// Custom implementing in backend
export const editProfile = (items) => {
  const formData = new FormData()
  for (let key of Object.keys(items)) {
    formData.append(key, items[key])
  }

  return http.post('/store/profile/', formData)
}

export const checkPhone = (number) => {
  const formData = new FormData()
  formData.append('mobile', number)
  return http.post('/store/change-mobile/', formData)
}

export const checkNewPhoneCode = (code) => {
  const formData = new FormData()
  formData.append('code', code)
  return http.put('/store/change-mobile/', formData)
}

export const changePassword = (data) => {
  const formData = new FormData()
  for (let key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return http.post('/store/profile/password-change/', formData)
}

export const getSocials = () => {
  return http.get('/store/social/')
}

export const setSocials = (socials) => {
  // adding socials
  const formData = new FormData()
  for (let key of socials) {
    formData.append(`social[${key.id}]`, key.username)
  }

  return http.post('/store/social/', formData)
}

export const getOwner = () => {
  return http.get('/store/owner/')
}

export const getConfirmData = () => {
  return http.get('/store/profile/confirm/')
}

export const sendConfirmData = (items) => {
  const formData = new FormData()
  for (let key of Object.keys(items)) {
    formData.append(key, items[key])
  }

  return http.post('/store/profile/confirm/', formData)
}

// get money and days of store credit
export const getDetails = () => {
  return http.get('/store/detail/')
}

export const getSatsInfo = () => {
  return http.get('/store/transaction/stars/')
}

export const getSatsDetail = (vars) => {
  const query = qs.stringify({
    ...vars
  })
  return http.get('/store/transaction/?' + query)
}

export const loginDirect = (token) => {
  return http.get('/login/token/' + token + '/')
}
