import http from './HttpService'

export const createReceiver = (data) => {
  const formData = new FormData()
  for (let key of Object.keys(data)) formData.append(key, data[key])

  return http.post('/store/cashier/manage/', formData)
}

export const editReceiver = (data) => {
  const formData = new FormData()
  for (let key of Object.keys(data)) formData.append(key, data[key])

  return http.put('/store/cashier/manage/', formData)
}

export const rechargeReceiver = ({ cashier_id, package_id }) => {
  const formData = new FormData()
  formData.append('package_id', package_id)

  return http.put('/store/cashier/' + cashier_id + '/', formData)
}

export const closeChest = (id) => {
  return http.get('/store/cashier/' + id + '/checkout/')
}

export const getReceiverPackages = () => {
  return http.get('/store/cashier/package/')
}
