const Customer = () =>
  import(/* webpackChunkName: "customer" */ '@view/store/customer/Customer')
const CustomerOffer = () =>
  import(
    /* webpackChunkName: "customer" */ '@view/store/customer/CustomerOffer'
  )
const CustomerBonus = () =>
  import(
    /* webpackChunkName: "customer" */ '@view/store/customer/CustomerBonus'
  )
const CustomerSatisfaction = () =>
  import(
    /* webpackChunkName: "customer" */ '@view/store/customer/CustomerSatisfaction'
  )
const CustomerShow = () =>
  import(/* webpackChunkName: "customer" */ '@view/store/customer/CustomerShow')
const CustomerSort = () =>
  import(/* webpackChunkName: "customer" */ '@view/store/customer/CustomerSort')

export default [
  // Main entrance
  {
    path: '/store/customer',
    name: 'Customer',
    component: Customer,
    meta: { requiresAuth: true }
  },
  // Show a customer
  {
    path: '/store/customer/:id',
    name: 'CustomerShow',
    component: CustomerShow,
    props: true,
    meta: { requiresAuth: true }
  },
  // Filtering customer
  {
    path: '/store/customer/:id/offer',
    name: 'CustomerOffer',
    component: CustomerOffer,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/customer/:id/bonus',
    name: 'CustomerBonus',
    component: CustomerBonus,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/customer/:id/sats',
    name: 'CustomerSatisfaction',
    component: CustomerSatisfaction,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/customer/sort',
    name: 'CustomerSort',
    component: CustomerSort,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/customer/location',
    name: 'CustomerLocation',
    component: () =>
      import(
        /* webpackChunkName: "customer" */ '@view/store/customer/CustomerLocation'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/store/customer/birth',
    name: 'CustomerBirthday',
    component: () =>
      import(
        /* webpackChunkName: "customer" */ '@view/store/customer/CustomerBirthday'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/store/customer/search',
    name: 'CustomerSearch',
    component: () =>
      import(
        /* webpackChunkName: "customer" */ '@view/store/customer/CustomerSearch'
      ),
    meta: { requiresAuth: true }
  }
]
