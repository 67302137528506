import * as ItemService from '@service/ItemService'

export const mutations = {
  ADD_ITEMS(state, items) {
    state.items = [...state.items, ...items]
  },
  ADD_BACKUP_ITEMS(state, items) {
    state.backupItems = [...state.backupItems, ...items]
  },
  CLEAR_ITEMS(state) {
    state.items = []
  },
  CLEAR_BACKUP_ITEMS(state) {
    state.backupItems = []
  },
  // Handle page
  INCREMENT_PAGE(state) {
    state.page++
  },
  RESET_PAGE(state) {
    state.page = 1
  },
  LAST_PAGE(state) {
    state.page = -1
  },
  // Handle count
  SET_TOTAL_COUNT(state, count) {
    state.itemsCount = count
  },
  // Handle page status
  SET_PAGE_STATUS(state, status) {
    state.pageStatus = status
  },
  SET_ITEM(state, item) {
    state.item = item
  },
  THEME_IS_LIGHT(state) {
    state.isDark = false
  },
  THEME_IS_DARK(state) {
    state.isDark = true
  }
}

export const actions = {
  async fetchItems(
    { state, commit },
    { part, backup = false, query = {} } = {}
  ) {
    const { page } = state
    if (page === -1) return commit('SET_PAGE_STATUS', 'resolved')
    // Start loading
    commit('SET_PAGE_STATUS', 'loading')

    // Handle query string items
    const newQuery = { ...query, page }

    try {
      const { data } = await ItemService.getItems(part, newQuery)
      backup
        ? commit('ADD_BACKUP_ITEMS', data['results'])
        : commit('ADD_ITEMS', data['results'])
      // Handle next page
      if (!data['next']) commit('LAST_PAGE')
      else commit('INCREMENT_PAGE')
      // Handle page status
      commit('SET_PAGE_STATUS', 'resolved')
    } catch (ex) {
      commit('SET_PAGE_STATUS', 'failed')
    }
  },
  clearItems({ commit }) {
    commit('RESET_PAGE')
    commit('CLEAR_ITEMS')
    commit('THEME_IS_LIGHT')
    commit('SET_PAGE_STATUS', '')
  },
  clearBackupItems({ commit }) {
    commit('RESET_PAGE')
    commit('CLEAR_BACKUP_ITEMS')
    commit('SET_PAGE_STATUS', '')
  },
  async fetchItem({ commit }, { part, id }) {
    commit('SET_PAGE_STATUS', 'loading')
    try {
      const { data } = await ItemService.getItem(part, id)
      if (data['status'] && data['status'] !== '1') commit('THEME_IS_DARK')
      commit('SET_ITEM', data)
      commit('SET_PAGE_STATUS', 'resolved')
    } catch (ex) {
      commit('SET_PAGE_STATUS', 'failed')
    }
  },
  clearItem({ commit }) {
    commit('SET_ITEM', null)
    commit('THEME_IS_LIGHT')
  },
  async fetchRecords(
    { state, commit },
    { url, query = {}, saveInBackup = false } = {}
  ) {
    const { page } = state
    if (page === -1) return commit('SET_PAGE_STATUS', 'resolved')

    const newQuery = { ...query, page }

    commit('SET_PAGE_STATUS', 'loading')
    try {
      const { data } = await ItemService.getRecords({ url, query: newQuery })
      saveInBackup
        ? commit('ADD_BACKUP_ITEMS', data['results'])
        : commit('ADD_ITEMS', data['results'])

      if (!data['next']) commit('LAST_PAGE')
      else commit('INCREMENT_PAGE')

      commit('SET_PAGE_STATUS', 'resolved')
    } catch (ex) {
      commit('SET_PAGE_STATUS', 'failed')
    }
  },
  async fetchRecord({ state, commit }, { url, query = {} } = {}) {
    commit('SET_PAGE_STATUS', 'loading')
    try {
      const { data } = await ItemService.getRecords({
        url,
        query
      })
      if (data['status'] && data['status'] !== '1') commit('THEME_IS_DARK')
      commit('SET_ITEM', data)
      commit('SET_PAGE_STATUS', 'resolved')
    } catch (ex) {
      commit('SET_PAGE_STATUS', 'failed')
    }
  }
}
