<template>
  <modal
    :is-open="isOpen"
    @modalClosed="$emit('modalconfirmclosed')"
    height="174px"
  >
    <div class="confirm-modal-wrapper">
      <p class="filtered-title" v-text="text"></p>
      <div class="credit-buttons buttons--filter">
        <button
          class="button button--filter"
          @click="$emit('modalconfirmcancelled')"
        >
          <svg class="button__icon" viewBox="0 0 512 512">
            <path
              d="M357.1 154.91l-0.01 -0.01c-5.23,-5.23 -13.8,-5.24 -19.04,0l-82.05 82.05 -82.05 -82.05c-5.24,-5.24 -13.81,-5.23 -19.04,0l-0.01 0.01c-5.23,5.23 -5.24,13.8 0,19.04l82.05 82.05 -82.05 82.05c-5.24,5.24 -5.23,13.81 0,19.04l0.01 0.01c5.23,5.23 13.8,5.24 19.04,0l82.05 -82.05 82.05 82.05c5.24,5.24 13.81,5.23 19.04,0l0.01 -0.01c5.23,-5.23 5.24,-13.8 0,-19.04l-82.05 -82.05 82.05 -82.05c5.24,-5.24 5.23,-13.81 0,-19.04z"
            />
          </svg>
        </button>
        <button
          class="button button--filter button--accept"
          @click="$emit('confirm')"
        >
          <svg v-show="!isLoading" class="button__icon" viewBox="0 0 512 512">
            <path
              d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
            />
          </svg>
          <spinner v-if="isLoading" bg-color="#ddd" />
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@common/Modal'
import Spinner from '@common/Spinner'

export default {
  name: 'ModalConfirm',
  components: {
    Modal,
    Spinner
  },
  emits: ['modalconfirmclosed', 'modalconfirmcancelled', 'confirm'],
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.confirm-modal-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #eee;
}
.filtered-title {
  margin: 40px 0;
}
.buttons--filter {
  justify-content: space-between;
  margin: 0 0 18px;
  padding: 0 15px;
}
.button--filter {
  width: 49%;
  background-color: #ddd;
}
.button--filter .button__icon {
  fill: #555;
}
.button--filter .button__text {
  font-size: 16px;
}
.button--accept {
  background-color: #fff;
}
.button--accept .button__icon {
  fill: #c69d4e;
  margin: 0;
}
</style>
