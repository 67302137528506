const Reminder = () =>
  import(
    /* webpackChunkName: "event-reminder" */ '@view/store/reminder/Reminder'
  )
const ReminderHome = () =>
  import(
    /* webpackChunkName: "event-reminder" */ '@view/store/reminder/ReminderHome'
  )
const ReminderStat = () =>
  import(
    /* webpackChunkName: "event-reminder" */ '@view/store/reminder/ReminderStat'
  )
const ReminderNew = () =>
  import(
    /* webpackChunkName: "event-reminder" */ '@view/store/reminder/ReminderNew'
  )

export default [
  {
    path: '/store/reminder',
    name: 'Reminder',
    component: Reminder,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'ReminderHome',
        component: ReminderHome
      },
      {
        path: 'stat',
        name: 'ReminderStat',
        component: ReminderStat
      },
      {
        path: 'new/:mode/:id?',
        name: 'ReminderNew',
        component: ReminderNew,
        props: true
      }
    ]
  }
]
