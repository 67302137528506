<template>
  <teleport to="#toast" :disabled="false">
    <transition-group
      :class="['toast-wrapper', toasts.length > 0 && 'toast--visible']"
      name="toast"
      tag="div"
    >
      <toast v-for="toast in toasts" :key="toast.id" :toast="toast" />
      <!-- <toast v-for="toast in toasts" :key="toast.id" :toast="toast" /> -->
    </transition-group>
  </teleport>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Toast from '@common/BaseToast'
// import { ref } from 'vue'

export default {
  name: 'ToastList',
  components: {
    Toast
  },
  setup() {
    const store = useStore()
    const toasts = computed(() => store.state.toasts)

    return { toasts }
  }
}
</script>

<style lang="scss" scoped>
.toast-wrapper {
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 80px;
  z-index: 300;
  visibility: hidden;
}

.toast--visible {
  visibility: visible;
}

/** TOAST animation */
// enter
.toast-enter-active {
  animation: wobble 0.3s ease-in-out;
}

@keyframes wobble {
  0% {
    opacity: 0;
    transform: translateY(calc(100vh + 120px));
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// leave
.toast-leave-to {
  opacity: 0;
  transform: translateY(calc(100vh + 120px));
}
.toast-leave-active {
  transition: all 0.5s ease-in-out;
}
</style>
