import Joi from 'joi'

export const validateForms = (items, schema) => {
  // Define options for joi
  const options = { abortEarly: false }

  // Validation
  const { error } = schema.validate(items, options)

  // If everything is fine
  if (!error) return null

  // If there is some error
  const errors = []
  for (let item of error.details) {
    errors.push(item.message)
  }
  return errors
}

export const schemaMaker = (rules) => {
  return Joi.object({ ...rules })
}

// validation rules
export const mobile = Joi.string()
  .required()
  .pattern(/^(09)[0-9]{9}$/)
  .label('شماره موبایل')
  .messages({
    'string.empty': `وارد کردن {#label} الزامی است.`,
    'string.pattern.base': `{#label} صحیح نیست.`
  })

export const reqMobile = (title = null) =>
  Joi.string()
    .required()
    .pattern(/^(09)[0-9]{9}$/)
    .label(title ?? 'شماره موبایل')
    .messages({
      'string.empty': `وارد کردن {#label} الزامی است.`,
      'string.pattern.base': `{#label} صحیح نیست.`
    })

export const price = (title, minPrice) =>
  // return a function
  Joi.number()
    .required()
    .min(minPrice)
    .label(title)
    .messages({
      'number.base': `وارد کردن  {#label} الزامی است.`,
      'number.min': `حداقل مبلغ  ${minPrice.toLocaleString()} ریال می‌باشد.`
    })

export const reqString = (label, message = null) =>
  Joi.string()
    .required()
    .label(label)
    .messages({
      'string.empty': message ?? `وارد کردن {#label} الزامی است.`
    })

export const reqObject = (label, message = null) =>
  Joi.object()
    .required()
    .label(label)
    .messages({
      'object.base': message ?? `انتخاب {#label} الزامی است.`
    })

export const reqNumber = (label, message = null) =>
  Joi.number()
    .required()
    .label(label)
    .messages({
      'number.base': message ?? `وارد کردن {#label} الزامی است.`
    })

// validating a base64 image
export const image = (label) =>
  Joi.string()
    .required()
    .label(label)
    .messages({
      'string.empty': `انتخاب {#label} الزامی است.`
    })

export const modalNumber = (title, minimum) =>
  // return a function
  Joi.number()
    .required()
    .min(minimum)
    .label(title)
    .messages({
      'number.base': `وارد کردن  {#label} الزامی است.`,
      'number.min': `حداقل مقدار {#label} ${minimum}  می‌باشد.`
    })

export const priceLess = (title, maxPrice) =>
  // return a function
  Joi.number()
    .required()
    .max(maxPrice)
    .label(title)
    .messages({
      'number.base': `وارد کردن  {#label} الزامی است.`,
      'number.max': `"قیمت جدید" باید از "قیمت اصلی" کمتر باشد.`
    })

export const number = (title, minimum, message = null) =>
  // return a function
  Joi.number()
    .required()
    .min(minimum)
    .label(title)
    .messages({
      'number.base': `وارد کردن  {#label} الزامی است.`,
      'number.min': message ?? `حداقل مقدار {#label} ${minimum}  می‌باشد.`
    })

export const maxNumber = (title, maximum, message = null) =>
  // return a function
  Joi.number()
    .required()
    .max(maximum)
    .label(title)
    .messages({
      'number.base': `وارد کردن  {#label} الزامی است.`,
      'number.max': message ?? `حداکثر تعداد {#label} ${maximum}  می‌باشد.`
    })

export const password = (title) =>
  Joi.string()
    .required()
    .pattern(/^[a-zA-Z0-9]{6,}$/)
    .label(title)
    .messages({
      'string.empty': `وارد کردن {#label} الزامی است.`,
      'string.pattern.base': `{#label} حداقل 6 کاراکتر شامل حروف و اعداد انگلیسی می ‌باشد.`
    })

export const passwordRepeat = (source) =>
  Joi.any()
    .equal(Joi.ref(source))
    .required()
    .label('تکرار رمز عبور')
    .messages({ 'any.only': 'رمز عبور با تکرار آن مطابقت ندارد.' })
