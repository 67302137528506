export const state = {
  customerMobile: '',
  customerPrice: '',
  customerId: '',
  customerName: '',
  customerGender: '',
  giftCode: '',
  registerModal: false,
  newCustomerModal: false
}

export const mutations = {
  UPDATE_FORM_ITEMS(state, { field, value }) {
    state[field] = value
  },
  SET_CUSTOMER_ID(state, id) {
    state.customerId = id
  },
  SET_CUSTOMER_GENDER(state, g) {
    state.customerGender = g
  },
  CLEAR_FORM(state) {
    state.customerMobile = ''
    state.customerPrice = ''
    state.customerName = ''
    state.customerGender = ''
    state.giftCode = ''
  },
  SET_GIFT_CODE(state, code) {
    state.giftCode = code
  }
}

export const actions = {
  clearFooterForm({ commit }) {
    commit('CLEAR_FORM')
  },
  handleModalFooter({ state }, bool) {
    state.registerModal = bool
  },
  handleCustomerModal({ state }, bool) {
    state.newCustomerModal = bool
  }
}
