import * as ProductService from '@service/ProductService'
import * as commonItems from './commonItems'

export const namespaced = true

export const state = {
  items: [],
  pageStatus: '',
  page: 1,
  itemsCount: 0,
  packages: [],
  payments: []
}
export const mutations = {
  ...commonItems.mutations,
  SET_PACKAGES(state, data) {
    state.packages = data
  }
}
export const actions = {
  ...commonItems.actions,
  async fetchPackages({ commit }) {
    commit('SET_PAGE_STATUS', 'loading')
    try {
      const { data } = await ProductService.getPackages()
      commit('SET_PACKAGES', data.results)
      commit('SET_PAGE_STATUS', 'resolved')
    } catch (ex) {
      commit('SET_PAGE_STATUS', 'failed')
    }
  },
  clearPackages({ commit }) {
    commit('SET_PACKAGES', [])
    commit('SET_PAGE_STATUS', '')
  }
}
