import http from './HttpService'
import qs from 'querystring'

export const disableBonus = (id) => {
  return http.get('/store/bonus/' + id + '/disable/')
}

export const addBonus = (data) => {
  const formData = new FormData()
  for (let key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return http.post('/store/bonus/', formData)
}

export const getBonusItems = (id, status, page) => {
  const query = qs.stringify({
    bonus: id,
    status,
    page
  })
  return http.get('/store/bonusItem/?' + query)
}

export const getAllBonusItems = (id) => {
  const activeApiCall = getBonusItems(id, '1', 1)
  const usedApiCall = getBonusItems(id, '2', 1)
  const expiredApiCall = getBonusItems(id, '3', 1)

  return Promise.all([activeApiCall, usedApiCall, expiredApiCall])
}

export const addSingleBonus = (data) => {
  const formData = new FormData()
  for (let key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return http.post('/store/bonus/single/', formData)
}
