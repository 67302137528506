import http from './HttpService'

export const getPackages = () => {
  return http.get('/product/package/')
}

export const getPackage = (id) => {
  return http.get('/product/package/' + id + '/')
}

export const payPrice = (price) => {
  const formData = new FormData()
  formData.append('price', price)

  return http.post('/store/pay/', formData)
}
