<template>
  <div class="login-wrapper">
    <img class="login__img" src="@img/login-bg.jpg" />
    <div class="login__logos">
      <img class="login__logo" src="@img/vipon-loading.svg" />
      <img class="login__logo" src="@img/vipon-loading-white.svg" />
    </div>
    <form
      novalidate
      class="login-form"
      @submit.prevent="$emit('install-clicked')"
      v-if="browser == 'chrome'"
    >
      <h1 class="login-form__title">برای استفاده، اپلیکیشن را نصب نمایید.</h1>
      <div class="credit-buttons">
        <button
          type="submit"
          :disabled="!installEvt"
          class="button button--login"
        >
          <span class="button__text">نصب</span>
        </button>
      </div>
    </form>
    <div
      class="login__apple"
      v-else-if="browser == 'ios' || browser == 'safari'"
    >
      <ul>
        <li>
          گزینه
          <a>
            <svg viewBox="0 0 50 50">
              <path d="M30.3 13.7L25 8.4l-5.3 5.3-1.4-1.4L25 5.6l6.7 6.7z" />
              <path d="M24 7h2v21h-2z" />
              <path
                d="M35 40H15c-1.7 0-3-1.3-3-3V19c0-1.7 1.3-3 3-3h7v2h-7c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V19c0-.6-.4-1-1-1h-7v-2h7c1.7 0 3 1.3 3 3v18c0 1.7-1.3 3-3 3z"
              />
            </svg>
          </a>
          در پایین صفحه را انتخاب نمایید.
        </li>
        <li>
          گزینه
          <a>
            <svg
              viewBox="0 0 477.867 477.867"
              style="width: 20px; height: 20px"
            >
              <path
                d="M392.533,0h-307.2C38.228,0.056,0.056,38.228,0,85.333v307.2c0.056,47.105,38.228,85.277,85.333,85.333h307.2
                  c47.105-0.056,85.277-38.228,85.333-85.333v-307.2C477.81,38.228,439.638,0.056,392.533,0z M443.733,392.533
                  c0,28.277-22.923,51.2-51.2,51.2h-307.2c-28.277,0-51.2-22.923-51.2-51.2v-307.2c0-28.277,22.923-51.2,51.2-51.2h307.2
                  c28.277,0,51.2,22.923,51.2,51.2V392.533z"
              />
              <path
                d="M324.267,221.867H256V153.6c0-9.426-7.641-17.067-17.067-17.067s-17.067,7.641-17.067,17.067v68.267H153.6
                  c-9.426,0-17.067,7.641-17.067,17.067S144.174,256,153.6,256h68.267v68.267c0,9.426,7.641,17.067,17.067,17.067
                  S256,333.692,256,324.267V256h68.267c9.426,0,17.067-7.641,17.067-17.067S333.692,221.867,324.267,221.867z"
              />
            </svg>
          </a>
          <span style="color: #c69d4e">Add to Home Screen</span>
        </li>
        <li>گزینه <span style="color: #c69d4e">Add</span> را انتخاب نمایید.</li>
      </ul>
    </div>
    <form
      novalidate
      class="login-form"
      @submit.prevent="$emit('install-clicked')"
      v-else
    >
      <h1 class="login-form__title" style="margin-bottom: 20px;">
        Open in Chrome
      </h1>
    </form>
    <div class="login__copy">
      <p>All Rights Reserved By <a href="www.vipon.ir">VIPON</a></p>
      <p>&copy; 2021</p>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { detect } from 'detect-browser'

export default {
  name: 'LoginInstall',
  props: {
    installEvt: { type: Boolean }
  },
  setup() {
    // detect browser
    const result = detect()
    const browser = ref(result.name)

    return { browser }
  },
  computed: {
    device() {
      return this.$store.state.device
    }
  }
}
</script>

<style lang="scss" scoped>
.login-wrapper {
  position: relative;
  padding: 350px 0 20px;
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login__img {
  position: absolute;
  height: 700px;
  top: -185px;
  object-fit: cover;
  object-position: center;
}
.login__logos {
  position: absolute;
  left: 0;
  right: 0;
  top: 150px;
  margin: 0 auto;
  width: 120px;
  height: 180px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.login__logo {
  width: 120px;
  height: 120px;
  &:last-child {
    transform: translateY(-20px);
  }
}
.login-form {
  z-index: 1;
  width: 96%;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(#fff, 0.03);
  margin-bottom: 25px;
}

.login-form__title {
  margin: 20px 0 0;
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

.login__apple {
  width: calc(100% - 40px);
  z-index: 1;
  color: #fff;
  margin: 0 0 20px;
  padding: 15px 15px 0;
  background-color: rgba(#fff, 0.06);
  border-radius: 15px;
  ul {
    counter-reset: rules;
    display: flex;
    flex-direction: column;
  }
  li {
    position: relative;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: justify;
    padding-right: 40px;
    line-height: 25px;
    a {
      display: inline-block;
      position: relative;
      width: 35px;
    }
    svg {
      left: 6px;
      right: 0;
      top: 0;
      bottom: 10px;
      margin: auto;
      width: 35px;
      height: 35px;
      fill: #c69d4e;
      position: absolute;
    }
  }
  li::before {
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 2px;
    margin: auto 0;
    width: 32px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    border-radius: 100%;
    font-size: 18px;
    padding-top: 3px;
    background-color: rgba(#777, 0.4);
    counter-increment: rules;
    content: counters(rules, '.') '';
  }
}

// change in common css
.credit-buttons {
  margin: 20px 0 25px;
  .button--login {
    width: 90%;
    background-color: #c69d4e;
    margin: 0;
    .button__text {
      color: #000;
    }
    &:disabled {
      cursor: default;
      background-color: rgba(#c69d4e, 0.5);
    }
    &:disabled .button__text {
      color: rgba(#000, 0.7);
    }
  }
}

.login__copy {
  z-index: 1;
  direction: ltr;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    font-size: 14px;
    color: #444;
  }
}
</style>
