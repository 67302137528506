<template>
  <div
    :class="[
      'input',
      lightTheme && 'input--light',
      phoneNumber && 'input--phone'
    ]"
    :style="{ width }"
  >
    <input
      :class="[
        'input__input',
        removeOnType && 'input--valid',
        startLeft && 'input--ltr',
        (endText || rial) && 'input--end-text'
      ]"
      :type="device == 'other-devices' ? inputType : ''"
      required
      autocomplete="off"
      :pattern="inputType === 'number' ? '[0-9]*' : '.*'"
      :value="modelValue"
      @input="updateValue"
    />
    <!-- :type="inputType" -->
    <label :class="['input__label', starLabel && 'label--star', animateType]">
      {{ label }}
    </label>
    <span class="input__text" v-if="endText || rial">
      {{ rial ? 'ریال' : endText }}
    </span>
    <i class="input__underline" />
  </div>
</template>

<script>
import persianJs from 'persianjs'

export default {
  name: 'BaseInput',
  props: {
    modelValue: {
      type: [String, Number]
    },
    label: {
      type: String
    },
    inputType: {
      type: String,
      default: 'text'
    },
    animateType: {
      type: String
    },
    rial: {
      type: Boolean,
      default: false
    },
    lightTheme: {
      type: Boolean,
      default: false
    },
    startLeft: {
      type: Boolean,
      default: false
    },
    phoneNumber: {
      type: Boolean,
      default: false
    },
    endText: {
      type: String
    },
    charCountToRemove: {
      type: Number,
      default: 4
    },
    starLabel: {
      type: Boolean,
      default: false
    },
    removeOnType: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '90%'
    }
  },
  emits: ['update:modelValue'],
  methods: {
    updateValue($event) {
      // Filter arabic letters
      const value = $event.target.value
        ? persianJs($event.target.value)
            .arabicChar()
            .toEnglishNumber()
            .toString()
        : $event.target.value
      this.$emit('update:modelValue', value)
    }
  },
  watch: {
    modelValue(newValue) {
      const el = this.$el.querySelector('label')
      if (this.endText || this.rial || this.phoneNumber) {
        if (newValue.length > this.charCountToRemove) {
          el.classList.add('remove')
        } else {
          el.classList.remove('remove')
        }
      }
    }
  },
  computed: {
    device() {
      return this.$store.state.device
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  margin: 12px 0;
  width: 90%;
  height: 40px;
  position: relative;
}
.input__text {
  position: absolute;
  left: 0;
  font-size: 15px;
  bottom: 8px;
  color: #000;
  pointer-events: none;
}
.input__input {
  font-size: 17px;
  height: 100%;
  width: 100%;
  color: #000;
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  text-align: right;
  padding: 2px 4px 0px;

  // Remove buttons of add and subtract
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
}
.input__underline {
  display: block;
  overflow: hidden;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 2px;
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    background-color: #c69d4e;
    width: 100%;
    height: 100%;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.5s ease;
  }
}
.input__label {
  position: absolute;
  font-size: 15px;
  top: 12px;
  right: 5px;
  color: #b4b4b4;
  pointer-events: none;
  transition: all 0.5s ease;
}
// Modifiers
.label--star::before {
  position: relative;
  content: '*';
  color: #c69d4e;
  font-size: 20px;
  top: 6px;
  margin-left: 3px;
}
.input--phone {
  height: 44px;
  .input__input {
    font-size: 30px;
  }
}
.input--end-text .input__input {
  padding-left: 33px;
  font-size: 25px;
}
.input--ltr {
  direction: ltr;
  text-align: left;
}
.input--light .input__input {
  border-bottom: 1px solid #777;
  color: white;
}
.input--light .input__text {
  color: #777;
}

// Animations
.input__input:focus ~ .input__underline::before {
  transform: translateX(0);
}
.input__input:focus ~ .input__label.decrease,
.input__input:valid ~ .input__label.decrease {
  transform: translate(3px, -28px);
  font-size: 15px;
}
.input__input:valid ~ .input__label.remove {
  opacity: 0;
}
.input--valid:focus ~ .input__label.remove {
  opacity: 0;
}
</style>
