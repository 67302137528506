<template>
  <div class="input" tabindex="0" @focus="isFocused = true">
    <div class="input__inner">
      <div class="input__row">
        <span class="input__rial" v-if="rial || rialText">{{ rialText }}</span>
        <p :class="['input__text', isFocused && 'input--focused']">
          {{ reformatNumber }}
        </p>
      </div>
      <label :class="['input__label', star && 'label--star']">
        {{ label }}
      </label>
      <i class="input__line" />
      <i :class="['input__underline', isFocused && 'underline--focused']" />
    </div>
    <input
      lang="en"
      ref="hiddenInput"
      pattern="[0-9]*"
      autocomplete="off"
      class="input__input"
      :type="device == 'other-devices' && 'number'"
      :value="modelValue"
      @input="updateValue"
      @blur="isFocused = false"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseInputNumber',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [String, Number]
    },
    rial: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    star: {
      type: Boolean,
      default: false
    },
    rialText: {
      type: String,
      default: 'ريال'
    }
  },
  data() {
    return {
      isFocused: false
    }
  },
  methods: {
    faNumbersToEnNumbers(n) {
      return n.replace(/[۰-۹]/g, (d) => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
    },
    arNumbersToEnNumbers(n) {
      return n.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
    },
    updateValue($event) {
      let n
      n = String(this.arNumbersToEnNumbers($event.target.value))
      n = String(this.faNumbersToEnNumbers(n))
      if (n.length > 15) {
        const newVal = n.substr(0, 15)
        this.$emit('update:modelValue', newVal)
        n = newVal
      } else this.$emit('update:modelValue', n)
    }
  },
  computed: {
    device() {
      return this.$store.state.device
    },
    reformatNumber() {
      const n = this.modelValue
      // if (n) return parseInt(n).toLocaleString()
      if (n) return new Intl.NumberFormat('en-US').format(n)
      else return ''
    }
  },
  watch: {
    isFocused(curr) {
      if (curr) {
        this.$refs['hiddenInput'].focus()
      }
    },
    modelValue(newValue) {
      const el = this.$el.querySelector('label')
      if (newValue.length > 8) {
        el.classList.add('label--remove')
      } else {
        el.classList.remove('label--remove')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  width: 90%;
  height: 40px;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: text;
}
.input__inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.input__row {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  left: 0;
  top: 0;
  bottom: 0;
  // position: absolute;
  direction: ltr;
}
.input__input {
  text-align: left;
  direction: ltr;
  // visibility: hidden;
  opacity: 0;
  width: 100%;
  // width: 0;
  // height: 0;
}
.input__line {
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #000;
}
.input__underline {
  overflow: hidden;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 2px;
  z-index: 2;
  &::before {
    content: '';
    position: absolute;
    background-color: #c69d4e;
    width: 100%;
    right: 0;
    height: 100%;
    transform: translateX(100%);
    transition: transform 0.5s ease;
  }
}
.underline--focused {
  &::before {
    transform: translateX(0);
  }
}
.input__text {
  display: inline-block;
  min-width: 2px;
  height: 40px;
  text-align: left;
  font-size: 25px;
  padding: 8px 0 0;
}
.input__rial {
  display: inline-block;
  font-size: 15px;
  color: #000;
  margin-right: 10px;
}
.input--focused {
  border-right: 1px solid black;
  animation: cursorBlink 1.2s infinite;
}
.input__label {
  position: absolute;
  top: 9px;
  right: 4px;
  bottom: 0;
  font-size: 15px;
  color: #b4b4b4;
  pointer-events: none;
  transition: all 0.5s ease;
}
.label--remove {
  opacity: 0;
}
.label--star::before {
  position: relative;
  content: '*';
  color: #c69d4e;
  font-size: 20px;
  top: 6px;
  margin-left: 3px;
}

@keyframes cursorBlink {
  0% {
    border-right-color: black;
  }
  50% {
    border-right-color: transparent;
  }
  100% {
    border-right-color: transparent;
  }
}
</style>
