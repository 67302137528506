<script setup>
import { ref, computed, watchEffect, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const currentPage = ref('phone')
const currentComponent = computed(() => {
  switch (currentPage.value) {
    case 'phone':
      return defineAsyncComponent(() => import('./FooterCustomerPhone'))
    case 'register':
      return defineAsyncComponent(() => import('./FooterCustomerRegister'))

    default:
      return defineAsyncComponent(() => import('./FooterCustomerPhone'))
  }
})
const formHeight = ref('254px')
const isOpen = ref(false)
const customerModal = computed({
  get() {
    return store.state.footer.newCustomerModal
  },
  set(value) {
    store.dispatch('handleCustomerModal', value)
  }
})
watchEffect(() => {
  if (customerModal.value) updateForm('register')
})

const updateForm = (form) => {
  switch (form) {
    case 'phone':
      formHeight.value = '254px'
      currentPage.value = 'phone'
      break

    case 'register':
      formHeight.value = '330px'
      currentPage.value = 'register'
      break

    default:
      break
  }
}

const refreshForm = () => {
  store.dispatch('clearFooterForm')
  customerModal.value = false
  isOpen.value = false
  formHeight.value = '254px'
  currentPage.value = 'phone'
}

const handleSetPhone = (status) => {
  if (status == 'success') {
    updateForm('register')
  } else {
    refreshForm()
  }
}

const handleSubmitCustomer = (status) => {
  if (status == 'success') {
    store.dispatch('addToast', {
      type: 'success',
      message: 'مشتری با موفقیت ثبت شد.'
    })
    refreshForm()
  } else {
    store.dispatch('addToast', {
      type: 'error',
      message: 'درخواست شما موفقیت‌آمیز نبود.'
    })
  }
}
</script>

<template>
  <li class="footer__item">
    <a class="footer__link" @click="isOpen = true">
      <svg
        class="footer__icon"
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
        viewBox="0 0 700 512"
      >
        <path
          d="M93.53 512l27.14 0c3.7,-122.99 104.9,-221.89 228.77,-221.89 79.98,0 145.05,-65.08 145.05,-145.06 0,-79.97 -65.07,-145.05 -145.05,-145.05 -79.98,0 -145.05,65.07 -145.05,145.05 0,47.94 23.62,92.43 62.99,119.54l11.39 7.85 -13.07 4.51c-36.72,12.66 -69.82,33.55 -97.28,61.01 -46.71,46.71 -73.14,108.27 -74.89,174.04zm255.91 -249.01c-65.03,0 -117.93,-52.91 -117.93,-117.94 0,-65.03 52.9,-117.93 117.93,-117.93 65.03,0 117.93,52.9 117.93,117.93 0,65.03 -52.9,117.94 -117.93,117.94zm170.73 49.31l-27.12 0 0 86.29 -86.29 0 0 27.12 86.29 0 0 86.29 27.12 0 0 -86.29 86.3 0 0 -27.12 -86.3 0 0 -86.29z"
        />
      </svg>
    </a>
  </li>
  <modal
    type="bottom"
    :is-open="isOpen || customerModal"
    @modalClosed="refreshForm"
    :height="formHeight"
  >
    <component
      :is="currentComponent"
      @setCustomerPhone="handleSetPhone"
      @submitNewCustomer="handleSubmitCustomer"
    />
  </modal>
</template>
