import * as ReceiverService from '@service/ReceiverService'
import * as commonItems from './commonItems'

export const namespaced = true

export const state = {
  itemsCount: 0,
  items: [],
  page: 1,
  pageStatus: '',
  item: null,
  isDark: false,
  backupItems: [],
  packages: []
}

export const mutations = {
  ...commonItems.mutations,
  SET_RECEIVER_PACKAGES(state, packages) {
    state.packages = packages
  }
}

export const actions = {
  ...commonItems.actions,
  async fetchReceiverPackages({ commit }) {
    try {
      const { data } = await ReceiverService.getReceiverPackages()
      commit('SET_RECEIVER_PACKAGES', data)
    } catch (ex) {
      console.log(ex)
    }
  }
}
