<template>
  <div class="stage">
    <div class="vipon-loading">
      <img src="@img/vipon-loading.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    type: {
      type: String,
      default: 'vipon'
    }
  }
}
</script>

<style lang="scss" scoped>
.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0;
  margin: 20px 0;
  overflow: hidden;
  width: 100px;
  height: 100px;
}

.vipon-loading {
  padding-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  img {
    width: 150px;
    height: 150px;
  }
}
</style>
