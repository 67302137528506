export const setToken = (tokenObj) => {
  localStorage.setItem('accessToken', tokenObj['access'])
  localStorage.setItem('refreshToken', tokenObj['refresh'])
  localStorage.setItem('completed', tokenObj['completed'])
}

export const setAccessToken = (tokenObj) => {
  localStorage.setItem('accessToken', tokenObj['access'])
}

export const getAccessToken = () => {
  return localStorage.getItem('accessToken')
}

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken')
}

export const isComplete = () => {
  return JSON.parse(localStorage.getItem('completed'))
}

export const setRegisterComplete = () => {
  localStorage.setItem('completed', true)
}

export const clearToken = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('completed')
}

export const localStorageHasTokens = () => {
  const accessToken = getAccessToken()
  const refreshToken = getRefreshToken()
  const completed = isComplete()

  return accessToken && refreshToken && typeof completed === 'boolean'
}

export const setTempData = (data) => {
  localStorage.setItem('tempData', JSON.stringify(data))
}

export const getTempData = () => {
  return JSON.parse(localStorage.getItem('tempData'))
}

export const clearTempData = () => {
  localStorage.removeItem('tempData')
}
