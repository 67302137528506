import * as commonItems from './commonItems'

export const namespaced = true

export const state = {
  itemsCount: 0,
  items: [],
  page: 1,
  pageStatus: '',
  item: null
}

export const mutations = {
  ...commonItems.mutations
}

export const actions = {
  ...commonItems.actions
}
