const Notice = () =>
  import(/* webpackChunkName: "notice" */ '@view/store/notice/Notice')
const NoticeHome = () =>
  import(/* webpackChunkName: "notice" */ '@view/store/notice/NoticeHome')
const NoticeNew = () =>
  import(/* webpackChunkName: "notice" */ '@view/store/notice/NoticeNew')
const NoticeShow = () =>
  import(/* webpackChunkName: "notice" */ '@view/store/notice/NoticeShow')
const NoticeStat = () =>
  import(/* webpackChunkName: "notice" */ '@view/store/notice/NoticeStat')
const CustomerFilter = () =>
  import(/* webpackChunkName: "notice" */ '@view/store/customer/CustomerFilter')

export default [
  {
    path: '/store/notice',
    name: 'Notice',
    component: Notice,
    meta: { requriesAuth: true },
    children: [
      {
        path: '',
        name: 'NoticeHome',
        component: NoticeHome
      },
      {
        path: 'new',
        name: 'NoticeNew',
        component: NoticeNew
      },
      {
        path: 'filter/:destination',
        name: 'NoticeCustomerFilter',
        component: CustomerFilter,
        props: true
      },
      {
        path: 'stat',
        name: 'NoticeStat',
        component: NoticeStat
      }
    ]
  },
  {
    path: '/store/notice/:id',
    name: 'NoticeShow',
    component: NoticeShow,
    props: true
  }
]
