import http from './HttpService'
import qs from 'querystring'

export const getEvents = (page, perPage, status) => {
  const items = {
    limit: perPage,
    offset: (page - 1) * perPage,
    status
  }

  const query = qs.stringify(items)
  return http.get('/store/event/?' + query)
}

export const getEvent = (id) => {
  return http.get('/store/event/' + id + '/')
}

export const changeEvent = (data) => {
  const formData = new FormData()

  for (let key of Object.keys(data)) formData.append(key, data[key])

  return http.post('/store/event/', formData)
}

export const deleteEvent = (id) => {
  return http.delete('/store/event/' + id + '/')
}

export const getEventLogs = () => {
  return http.get('/store/event/log/')
}
