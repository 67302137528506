<template>
  <div class="loader">
    <div
      class="spinner"
      :style="{ borderColor: bgColor, borderLeftColor: color }"
    />
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    color: { type: String, default: '#c69d4e' },
    bgColor: { type: String, default: 'rgba(255, 255, 255, 0.2)' }
  }
}
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0;
  margin: 0 5px;
  overflow: hidden;
  width: 30px;
  height: 30px;
}
.spinner,
.spinner:after {
  border-radius: 100%;
  width: 30px;
  height: 30px;
}
.spinner {
  font-size: 10px;
  position: relative;
  text-indent: -9999px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-left-color: #c69d4e;
  // border-right: 3px solid rgba(255, 255, 255, 0.2);
  // border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  // border-left: 3px solid #c69d4e;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 0.6s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
