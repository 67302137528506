const Event = () =>
  import(/* webpackChunkName: "event-reminder" */ '@view/store/event/Event')
const EventEdit = () =>
  import(/* webpackChunkName: "event-reminder" */ '@view/store/event/EventEdit')
const EventStat = () =>
  import(/* webpackChunkName: "event-reminder" */ '@view/store/event/EventStat')
const EventHome = () =>
  import(/* webpackChunkName: "event-reminder" */ '@view/store/event/EventHome')
const EventNew = () =>
  import(/* webpackChunkName: "event-reminder" */ '@view/store/event/EventNew')

export default [
  {
    path: '/store/event',
    name: 'Event',
    component: Event,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'stat',
        name: 'EventStat',
        component: EventStat
      },
      {
        path: '',
        name: 'EventHome',
        component: EventHome
      },
      {
        path: 'new',
        name: 'EventNew',
        component: EventNew
      },
      {
        path: 'new/:mode/:id/',
        name: 'EventEdit',
        component: EventEdit,
        props: true
      }
    ]
  }
]
