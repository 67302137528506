import axios from 'axios'
import * as TokenService from './TokenService'
import store from '@/store'
// export const baseURL = 'http://192.168.1.50:8000'
export const baseURL = 'https://api.vipon.ir'

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500

  // use to seperate expected errors from unexpected
  if (!expectedError) {
    return store.dispatch('addToast', {
      type: 'error',
      message: 'برقراری ارتباط ممکن نمی‌باشد.'
    })
  }

  return Promise.reject(error)
})

const apiClient = axios.create({
  baseURL: baseURL + '/api/v1',
  withCredentials: true,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

apiClient.interceptors.response.use(null, async (error) => {
  const originalRequest = error.config
  const status = error.response.status
  switch (status) {
    // Handling auth
    case 401:
      // second try to get access token
      if (originalRequest.url === '/login/refresh/')
        store.dispatch('logoutUser')

      // first try to get access token
      if (!originalRequest._retry) {
        // Add exception for login
        if (originalRequest.url === '/login/partner') return

        originalRequest._retry = true
        const tokenService = TokenService.getRefreshToken()
        if (!tokenService) return Promise.reject(error)
        const res = await apiClient.post('/login/refresh/', {
          refresh: tokenService
        })
        if (res.status == 200) {
          // put new token in localstroge
          TokenService.setAccessToken(res?.data)
          // change header
          originalRequest.headers.Authorization = 'Bearer ' + res?.data

          // return originalRequest object with Axios.
          return apiClient(originalRequest)
        }
      }
      break

    // Handling not found page
    case 404:
      // store.dispatch('addToast', {
      //   type: 'error',
      //   message: 'صفحه مورد نظر پیدا نشد.'
      // })
      // return router.push({ name: 'Home' })
      return Promise.reject(error)

    // Handling store actions restriction
    case 412:
      store.dispatch('addToast', {
        type: 'error',
        message: 'شما به سقف استفاده روزانه از این امکان رسیده‌اید.'
      })
      return Promise.reject(error)

    // Handling package expiration
    case 422:
      store.dispatch('addToast', {
        type: 'error',
        message: 'در حال حاضر شما بسته فعالی ندارید.'
      })
      store.dispatch('handleNoPackage', true)
      return Promise.reject(error)

    case 417:
      store.dispatch('addToast', {
        type: 'error',
        message: 'لاگین از طریق این IP امکان‌پذیر نمی‌باشد.'
      })
      return Promise.reject(error)
    // return router.push({ name: 'Transaction' })

    // Handling accessibility restriction
    case 429:
      store.dispatch('addToast', {
        type: 'error',
        message: 'استفاده شما از سیستم محدود شده است.'
      })
      return Promise.reject(error)

    // Handling other states
    default:
      return Promise.reject(error)
  }
})

apiClient.interceptors.request.use(
  (config) => {
    const token = TokenService.getAccessToken()

    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default {
  get: apiClient.get,
  post: apiClient.post,
  put: apiClient.put,
  delete: apiClient.delete,
  patch: apiClient.patch
}
