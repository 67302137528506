import * as ProfileService from '@service/ProfileService'
import * as CoreService from '@service/CoreService'
import * as commonItems from './commonItems'

export const namespaced = true

export const state = {
  items: [],
  item: null,
  pageStatus: '',
  itemsCount: 0,
  page: 1,
  // ---------- //
  provinces: [],
  cities: [],
  isLoading: false,
  socials: [],
  payModal: false,
  creditModal: { isOpen: false, type: 'package' }
}

export const mutations = {
  ...commonItems.mutations,
  SET_PROVINCES(state, data) {
    state.provinces = data
  },
  SET_CITIES(state, data) {
    state.cities = data
  },
  SET_SOCIALS(state, data) {
    state.socials = data
  },
  OPEN_PAY_MODAL(state) {
    state.payModal = true
  },
  CLOSE_PAY_MODAL(state) {
    state.payModal = false
  },
  OPEN_CREDIT_MODAL(state) {
    state.creditModal.isOpen = true
  },
  CLOSE_CREDIT_MODAL(state) {
    state.creditModal.isOpen = false
  },
  SET_CREDIT_MODAL_TYPE(state, type) {
    state.creditModal.type = type
  },
  START_LOADING(state) {
    state.isLoading = true
  },
  STOP_LOADING(state) {
    state.isLoading = false
  }
}

export const actions = {
  ...commonItems.actions,
  // .......... //
  async fetchProvinces({ commit }) {
    try {
      const { data } = await CoreService.getProvinces()
      commit('SET_PROVINCES', data.results)
    } catch (ex) {
      console.log(ex)
    }
  },
  async fetchCities({ commit }, id) {
    commit('START_LOADING')
    try {
      const { data } = await CoreService.getCities(id)
      commit('SET_CITIES', data.results)
      commit('STOP_LOADING')
    } catch (ex) {
      console.log(ex)
      commit('STOP_LOADING')
    }
  },
  async fetchSocials({ commit }) {
    try {
      const { data } = await ProfileService.getSocials()
      commit('SET_SOCIALS', data)
    } catch (ex) {
      console.log(ex)
    }
  },
  handlePayModal({ commit }, status) {
    if (status == 'open') commit('OPEN_PAY_MODAL')
    else commit('CLOSE_PAY_MODAL')
  },
  handleCreditModal({ commit }, { status, type }) {
    commit('SET_CREDIT_MODAL_TYPE', type)
    if (status == 'open') commit('OPEN_CREDIT_MODAL')
    else commit('CLOSE_CREDIT_MODAL')
  }
}
