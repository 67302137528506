import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'

// Import global component
import Navbar from '@common/Navbar'
import FixedFooter from '@common/FixedFooter'
import Loader from '@common/Loader'
import Observer from '@common/Observer'
import Spinner from '@common/Spinner'
import Modal from '@common/Modal'
import ModalConfirm from '@common/ModalConfirm'
import BaseInput from '@common/BaseInput'
import FailedMode from '@common/FailedMode'

const app = createApp(App)
  .use(store)
  .use(router)

app.component('Navbar', Navbar)
app.component('FixedFooter', FixedFooter)
app.component('Loader', Loader)
app.component('Spinner', Spinner)
app.component('Observer', Observer)
app.component('Modal', Modal)
app.component('ModalConfirm', ModalConfirm)
app.component('BaseInput', BaseInput)
app.component('FailedMode', FailedMode)

// Sentry.init({
//   app,
//   dsn:
//     'https://4aa8d52cdf2244f190601f397accaf83@o506439.ingest.sentry.io/5884644',
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ['localhost', 'app.vipon.ir', /^\//]
//     })
//   ],
//   tracesSampleRate: 1.0
// })

app.mount('#app')
