<template>
  <main id="app-wrapper">
    <toast-list />
    <login-install
      v-if="!appPermission"
      :install-evt="!!installEvt"
      @installClicked="handleInstall"
    />
    <router-view v-else />
    <profile-no-credit-modal />
    <profile-pay-modal />
  </main>
</template>

<script>
import installPWA from '@mixin/installPWA'
import {
  getAccessToken,
  getRefreshToken,
  isComplete
} from '@service/TokenService'
import ToastList from '@common/ToastList'
import LoginInstall from '@view/store/login/LoginInstall'
import ProfilePayModal from '@view/store/profile/subComponents/ProfilePayModal'
import ProfileNoCreditModal from '@view/store/profile/subComponents/ProfileNoCreditModal'

export default {
  name: 'App',
  mixins: [installPWA],
  components: {
    ToastList,
    LoginInstall,
    ProfilePayModal,
    ProfileNoCreditModal
  },
  methods: {
    setDeivce() {
      const iPhoneXAspect = '0.462'
      const isIOS =
        /iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream
      const aspect = window.screen.width / window.screen.height
      const isIphoneX = isIOS && aspect.toFixed(3) == iPhoneXAspect

      if (isIOS && isIphoneX) this.$store.dispatch('setDevice', 'iphonex')
      else if (isIOS) this.$store.dispatch('setDevice', 'ios-devices')
      else this.$store.dispatch('setDevice', 'other-devices')
    }
  },
  created() {
    const accessToken = getAccessToken()
    const refreshToken = getRefreshToken()
    const completed = isComplete()

    if (accessToken && refreshToken && typeof completed === 'boolean') {
      const tokens = {
        refresh: refreshToken,
        access: accessToken,
        completed
      }
      this.$store.dispatch('loginUser', tokens)
    }

    this.setDeivce()

    // Disable right click
    // document.addEventListener('contextmenu', ($event) =>
    //   $event.preventDefault()
    // )
  }
}
</script>

<style lang="scss">
// Global scope for css files
@import '~destyle.css/destyle.css';
@import '~animate.css/animate.min.css';

@import '~@css/cropperjs.scss';
// @import '~@lib/date-picker/date-picker';
@import '@css/home';
@import '~@css/animations';
@import '~@css/common';
// includes navbar and footer styles
@import '~@css/navs';
// styles in common for the forms
@import '~@css/forms';

@font-face {
  font-family: 'IranSans';
  src: url(~@font/iran-sans.ttf) format('truetype');
}

@font-face {
  font-family: 'IranSans';
  src: url(~@font/iran-sans-bold.ttf) format('truetype');
  font-weight: bold;
}

body {
  width: 100%;
  font-family: 'IranSans';
  direction: rtl;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
}

*,
*::after,
*::before {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input,
textarea {
  user-select: initial;
}

#app,
.app-wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}

// Disable default style of router-link
a {
  &:hover {
    text-decoration: none;
  }
}
</style>
