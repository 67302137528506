import { createRouter, createWebHistory } from 'vue-router'
import * as TokenService from '@service/TokenService'
import store from '@/store'
import homeRoutes from './subRoutes/homeRoutes'
import profileRoutes from './subRoutes/profileRoutes'
import giftRoutes from './subRoutes/giftRoutes'
import customerRoutes from './subRoutes/customerRoutes'
import planRoutes from './subRoutes/planRoutes'
import receiverRoutes from './subRoutes/receiverRoutes'
import offerRoutes from './subRoutes/offerRoutes'
import noticeRoutes from './subRoutes/noticeRoutes'
import surveyRoutes from './subRoutes/surveyRoutes'
import bonusRoutes from './subRoutes/bonusRoutes'
import lotteryRoutes from './subRoutes/lotteryRoutes'
import eventRoutes from './subRoutes/eventRoutes'
import reminderRoutes from './subRoutes/reminderRoutes'
import learnRoutes from './subRoutes/learnRoutes'
import ticketRoutes from './subRoutes/ticketRoutes'

// Other routes
const TransactionPackage = () =>
  import(
    /* webpackChunkName: "other" */ '@view/store/transaction/TransactionPackage'
  )
const TransactionList = () =>
  import(
    /* webpackChunkName: "other" */ '@view/store/transaction/TransactionList'
  )

// Login
const Login = () =>
  import(/* webpackChunkName: "other" */ '@view/store/login/Login')

const routes = [
  ...homeRoutes, // checked - chunked
  ...profileRoutes, // checked - chunked
  ...giftRoutes, // checked - chunked
  ...customerRoutes, // checked - chunked
  ...planRoutes, // checked - chunked
  ...receiverRoutes, // checked - chunk
  ...offerRoutes, // checked - chunk
  ...noticeRoutes, // checked - chunk
  ...surveyRoutes, // checked - chunk
  ...bonusRoutes, // checked - chunk
  ...lotteryRoutes, // checked - chunk
  ...eventRoutes, // checked - chunk
  ...reminderRoutes, // checked - chunk
  ...learnRoutes, // chunk
  ...ticketRoutes,
  {
    path: '/store/transaction',
    name: 'TransactionList',
    component: TransactionList,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/package',
    name: 'TransactionPackage',
    component: TransactionPackage,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/discount/:mobile',
    name: 'FooterInquiry',
    component: () =>
      import(
        /* webpackChunkName: "other" */ '@view/store/footer/FooterInquiry'
      ),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/store/aboutus',
    name: 'ProfileAboutUs',
    component: () =>
      import(
        /* webpackChunkName: "other" */ '@view/store/profile/ProfileAboutUs'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/store/rules',
    name: 'ProfileRules',
    component: () =>
      import(
        /* webpackChunkName: "other" */ '@view/store/profile/ProfileRules'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/autoLogin',
    name: 'LoginRedirect',
    component: () =>
      import(
        /* webpackChunkName: "install" */ '@view/store/login/LoginRedirect'
      )
  },
  {
    path: '/store/login',
    name: 'Login',
    component: Login,
    beforeEnter: () => {
      // reject the navigation
      const accessToken = TokenService.getAccessToken()
      const refreshToken = TokenService.getRefreshToken()
      const completed = TokenService.isComplete()
      if (accessToken && refreshToken && typeof completed === 'boolean') {
        // store.dispatch('addToast', {
        //   type: 'error',
        //   message: 'برای خارج شدن گزینه خروج از سیستم را انتخاب کنید. '
        // })
        return false
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    const saveMode = store.state.routerSaveMode
    if (saveMode) {
      store.dispatch('handleSaveRoutes', from)
    } else {
      store.commit('SAVE_MODE_ACTIVE')
    }
    if (savedPosition) return savedPosition
    else return { left: 0, top: 0 }
  },
  routes
})

// Guarding
router.beforeEach((to, from, next) => {
  const loggedIn = TokenService.getRefreshToken()
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    store.dispatch('logoutUser')
    next(false)
  } else {
    const noPackageMode = store.state.noPackageMode
    if (
      noPackageMode &&
      !['Home', 'ProfilePay', 'TransactionPackage', 'Login'].includes(to.name)
    ) {
      store.dispatch('profile/handleCreditModal', {
        status: 'open',
        type: 'package'
      })
      next(false)
    } else next()
  }
})

// router.afterEach((to, from, failure) => {
//   if (!failure) {
//     ;(function(c, l, a, r, i, t, y) {
//       c[a] =
//         c[a] ||
//         function() {
//           ;(c[a].q = c[a].q || []).push(arguments)
//         }
//       t = l.createElement(r)
//       t.async = 1
//       t.src = 'https://www.clarity.ms/tag/' + i
//       y = l.getElementsByTagName(r)[0]
//       y.parentNode.insertBefore(t, y)
//     })(window, document, 'clarity', 'script', '6guwcxpaih')
//   }
// })

export default router
